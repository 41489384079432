// @mui
import {
  Typography,
  Stack,
  Box,
  Card,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";



const isWhite = false;

export default function EcgLegend() {
  const { translate } = useLocales()
  const LEGEND = [
    {
      color: "#EC5E58", // theme.palette.primary.main,
      name: translate('legend.pathology'),
    },
    {
      color: "#FBE74D", // theme.palette.primary.main,
      name: translate('legend.noise'),
    },
    {
      color: "#7ADDFB", // theme.palette.primary.main,
      name: translate('legend.other'),
    },
    {
      color: "#EA3423", // theme.palette.primary.main,
      name: translate('legend.arythmie'),
    },
    {
      color: "#C97AF8", // theme.palette.primary.main,
      name: translate('legend.sample'),
    },
  ];
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="subtitle1" component="h1" paragraph>
        {translate('legend.title')}
      </Typography>
      <Stack direction="row" spacing={4}>
        {LEGEND.map((legend,index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconColor
                color={legend.color}
                text={legend.name}
                sx={{
                  ...(isWhite && {
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                  }),
                }}
              />
            </Box>
          );
        })}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

function IconColor({ text, color, sx }) {
  return (
    <>
      <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
        <Box
          sx={{
            width: 40,
            height: 10,
            display: "flex",
            borderRadius: "5px",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: color,
            transition: (theme) =>
              theme.transitions.create("all", {
                duration: theme.transitions.duration.shortest,
              }),
            ...sx,
          }}
        ></Box>
        <Typography
          variant="body2"
          sx={{ alignItems: "center", mt: "0", mb: "0" }}
        >
          {text}
        </Typography>
      </Stack>
    </>
  );
}
