import { chunk, get, isEmpty, orderBy, set, toNumber } from "lodash";

export class EcgHelpers {


    static getCroix(peaks = [], xData = [], yData = []) {

        let newData = []

        peaks.forEach(peak => {
            let closest = xData.reduce((prev, curr) => {
                return (Math.abs(curr - peak) < Math.abs(prev - peak) ? curr : prev);
            });

            let idx = xData.findIndex(e => e === closest)
            newData.push([closest, yData[idx]])
        })
        return newData
    }



    static getBars(data = [], peaks = []) {
        let newData = []


        let lastEl = 4096
        let fifteenPrcnt = lastEl * 15 / 100
        peaks.forEach((e, i) => {

            newData.push(
                {
                    data: [
                        [e, 0 + fifteenPrcnt],
                        [e, lastEl - fifteenPrcnt],
                    ],
                    enableMouseTracking: false,
                    showInNavigator: false,
                    id: `bar-${i}`,
                    type: "line",
                    dashStyle: "Solid",
                    lineWidth: 2,
                    zIndex: 0,
                    color: '#44c220',
                    opacity: 0.7,
                    marker: {
                        enabled: false,
                        radius: 0,
                    },
                }
            )
        })

        return orderBy(newData, item => get(item, 'data[0][0]', ['asc']))



    }

    static getTimeInterPics(data = []) {
        let interPics = []

        if (!isEmpty(data)) {

            data.forEach((item, i) => {

                let prevItem = get(data, `${[i - 1]}`)

                if (!prevItem) return

                let xs = getDistance(item, prevItem)

                interPics.push({
                    x: (xs.x1 + xs.x2) / 2,
                    y: get(item, 'data[0][1]', 620),
                    value: String(Math.ceil(xs.x1 - xs.x2)),
                })


            })
        }
        return orderBy(interPics, i => i?.x, ['asc'])
    }

    static getRenderedPicsCroix(croix = [], START_ELEMENT, LAST_ELEMENT) {
        try {
            return croix.filter(e => get(e, '[0]', 0) >= START_ELEMENT && get(e, '[0]', 0) <= LAST_ELEMENT)
        } catch (err) {
            return croix
        }

    }

    static getRenderedPicsBarres(barres = [], START_ELEMENT, LAST_ELEMENT, minY = 0, maxY = 4096) {
        let diff = maxY - minY

        let fifteenPrcnt = (diff * 20) / 100


        try {
            let mappedBarres = barres.map(bar => get(bar, 'data[0][0]', 0))
            let e1 = findClosest(mappedBarres, START_ELEMENT)
            let i1 = binarySearch(mappedBarres, e1)
            let e2 = findClosest(mappedBarres, LAST_ELEMENT)
            let i2 = binarySearch(mappedBarres, e2)
            let slice = barres.slice(i1, i2)

            return slice.map(e => {
                set(e, 'data[0][1]', minY + fifteenPrcnt)
                set(e, 'data[1][1]', maxY - fifteenPrcnt)
                return e
            })
        } catch (err) {
            console.error(err);
            return barres
        }

    }
    static getArythmies(data = [], PR = 5) {
        if (isEmpty(data)) return []

        const arythmies = []
        data.forEach((item, index) => {

            //item 2 doit etre > item1+80%de item 1 donc item 2 arythmoie
            let item1 = item
            let item2 = get(data, `[${index + 1}]`, null)
            let item3 = get(data, `[${index + 2}]`, null)
            let item4 = get(data, `[${index + 3}]`, null)
            if (!item2 || !item3) return


            //Items xValues
            let xValue1 = get(item1, 'data[0][0]')
            let xValue2 = get(item4, 'data[0][0]')

            //distance entre les 3 points
            let distance1 = getDistance(item1, item2)
            let distance2 = getDistance(item2, item3)
            let distance3 = getDistance(item3, item4)

            //temps inter Pic
            let tip1 = distance1.x2 - distance1.x1
            let tip2 = distance2.x2 - distance2.x1
            let tip3 = distance3.x2 - distance3.x1

            //Calculer le pourcentage PR=5
            let tip1PR = tip1 + (tip1 * PR) / 100
            let _tip1PR = tip1 - (tip1 * PR) / 100

            let tip20PR = tip1 + (tip1 * 500) / 100
            let _tip20PR = tip1 - (tip1 * 500) / 100



            if (((tip2 > tip1PR) && (tip2 < tip20PR)) || (((tip2 < _tip1PR)) && (tip2 > _tip20PR))) {
                arythmies.push({
                    x1: xValue1 - 500,
                    x2: xValue2 + 500,
                    colorizedValues: [tip2]
                })
            }





        })
        return arythmies

    }


    static getHighestPoint(x, y, xdata = [], ydata = []) {

        //return closest number in array for getting index
        let e1 = findClosest(xdata, x)

        //Getting index of closest
        let closest_index = binarySearch(xdata, e1)

        let yValue = ydata[closest_index]
        //Check whether next index is descendent or not

        const IS_DESCENDENT = ydata[closest_index + 1] < yValue
        const IS_DESCENDENT_LEFT = ydata[closest_index - 1] < yValue

        //If it's false we keep going till we find an ascendent

        let final_value = getRightElement(IS_DESCENDENT, yValue, ydata, closest_index)
        let final_value_left = getLeftElement(IS_DESCENDENT_LEFT, yValue, ydata, closest_index)

        return x - xdata[final_value_left.idx] < xdata[final_value.idx] - x
            ? { value: [xdata[final_value_left.idx], final_value_left.final_value], index: final_value_left.idx }
            : { value: [xdata[final_value.idx], final_value.final_value], index: final_value.idx }


    }

    static getQuadrillageData = (chart, gridValue, min = 0) => {
        let xData = get(chart, 'series[0].processedXData', [])

        let x1 = xData[0]
        let x2 = xData[xData.length - 1]
        //10mm
        const TILE = 10

        const TILE_LENGTH = gridValue / TILE

        const END_Y_VALUE = chart?.yAxis[0].toPixels(4096)
        const START_Y_VALUE = chart?.yAxis[0].toPixels(0)
        const END_X_VALUE = chart?.xAxis[0].toPixels(x2)

        let time_in_sec = (x2 - x1) / 1000


        //How Many tile should be drawn
        let tile_total = Math.floor(time_in_sec * TILE_LENGTH)

        let step = time_in_sec / tile_total

        let arr = []
        let arrY = [min]
        arr.push([x1, START_Y_VALUE])
        while (arr[arr.length - 1][0] <= x2) {
            let val = arr[arr.length - 1][0] + (step * 1000)
            arr.push([val, START_Y_VALUE])
        }
        let stepY = 4096 / arr.length

        while (arrY[arrY.length - 1] <= 4096) {
            let val = arrY[arrY.length - 1] + (stepY)
            arrY.push(val)
        }

        return {
            data: arr,
            dataY: arrY,
            y_max: END_Y_VALUE,
            x_max: END_X_VALUE - START_Y_VALUE
        }



    }

}
const getDistance = (el1, el2) => {
    let x1 = get(el1, 'data[0][0]', 0)
    let x2 = get(el2, 'data[0][0]', 0)

    return { x1, x2 }
}

const getRightElement = (IS_DESCENDENT, yValue, ydata, closest_index) => {
    let final_value = null
    let idx = null
    let start_y_index = null

    if (IS_DESCENDENT) {
        let last_element = yValue
        let start_ascending = false
        for (let index = closest_index; index < ydata.length - 1; index++) {
            if (ydata[index + 1] < last_element) {
                last_element = ydata[index + 1]
            } else {
                start_ascending = true
                start_y_index = index + 1
                last_element = ydata[index + 1]
                break
            }
        }

        //check the time when it's going to descend
        if (start_ascending) {

            for (let index = start_y_index; index < ydata.length - 1; index++) {
                if (ydata[index + 1] > last_element) {
                    last_element = ydata[index + 1]
                    continue
                } else {
                    idx = index
                    final_value = last_element
                    break
                }
            }
        }
    } else {
        let last_element = yValue
        let start_y_index = closest_index
        for (let index = start_y_index; index < ydata.length - 1; index++) {
            if (ydata[index + 1] > last_element) {
                last_element = ydata[index + 1]
                continue
            } else {
                idx = index
                final_value = last_element
                break
            }
        }
    }

    return { final_value, idx }
}

const getLeftElement = (IS_DESCENDENT, yValue, ydata, closest_index) => {
    let final_value = null
    let idx = 0
    let start_y_index = null

    if (IS_DESCENDENT) {
        let last_element = yValue
        let start_ascending = false
        for (let index = closest_index; index > 0; index--) {
            if (ydata[index - 1] < last_element) {
                last_element = ydata[index - 1]
            } else {
                start_ascending = true
                start_y_index = index - 1
                last_element = ydata[index - 1]
                break
            }
        }

        //check the time when it's going to descend
        if (start_ascending) {

            for (let index = start_y_index; index > 0; index--) {
                if (ydata[index - 1] > last_element) {
                    last_element = ydata[index - 1]
                    continue
                } else {
                    idx = index
                    final_value = last_element
                    break
                }
            }
        }

    } else {
        let last_element = yValue
        let start_y_index = closest_index
        for (let index = start_y_index; index > 0; index--) {
            if (ydata[index - 1] > last_element) {
                last_element = ydata[index - 1]
                continue
            } else {
                idx = index
                final_value = last_element
                break
            }
        }
    }
    return { final_value, idx }

}
export function findClosest(arr, target, lo = 0, hi = arr.length - 1) {
    if (target < arr[lo]) { return arr[0] }
    if (target > arr[hi]) { return arr[hi] }

    const mid = Math.floor((hi + lo) / 2);

    return hi - lo < 2
        ? (target - arr[lo]) < (arr[hi] - target) ? arr[lo] : arr[hi]
        : target < arr[mid]
            ? findClosest(arr, target, lo, mid)
            : target > arr[mid]
                ? findClosest(arr, target, mid, hi)
                : arr[mid]
}


//
export function binarySearch(sortedArray, key) {
    let start = 0;
    let end = sortedArray.length - 1;

    while (start <= end) {
        let middle = Math.floor((start + end) / 2);

        if (sortedArray[middle] === key) {
            // found the key
            return middle;
        } else if (sortedArray[middle] < key) {
            // continue searching to the right
            start = middle + 1;
        } else {
            // search searching to the left
            end = middle - 1;
        }
    }
    // key wasn't found
    return -1;
}


export function newArythmiesDetection(tip = [], pr = 5) {
    if (isEmpty(tip)) return []

    //Get Values as array of numbers
    let values = tip.map(e => toNumber(e.value))

    let arythmiesIndex = []

    values.forEach((value, index) => {

        let currentValue = value
        let previousValue = get(values, `[${index - 1}]`, null)

        //If no previous value that mean its index 0
        if (!previousValue) return


        let percentagePlusPr = previousValue + (previousValue * pr) / 100
        let percentageMinusPr = previousValue - (previousValue * pr) / 100

        if ((currentValue > percentagePlusPr) || (currentValue < percentageMinusPr)) {
            arythmiesIndex.push({ x1: get(tip, `${[index]}.x`, 0) - 500, x2: get(tip, `${[index + 3]}.x`, 0) + 500, index })
        }

    })

    return arythmiesIndex



}