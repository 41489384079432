import { yupResolver } from '@hookform/resolvers/yup';
import { Close, Edit, WarningAmberOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { get, isEmpty, unset } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useLocales from 'src/hooks/useLocales';
import * as Yup from "yup";


function UpdateCaseModal({ loading, open, handleClose, onCancel, onUpdate, item }) {
    const { translate } = useLocales()
    const [canEditHorse, setCanEditHorse] = useState(false)
    const vetSchema = Yup.object().shape({
        case_number: Yup.string().required(translate('yup.folder.required')),
        horse: Yup.string().when([], {
            is: () => canEditHorse,
            then: Yup.string().required(translate('yup.horse.required')),
        }),
    });

    useEffect(() => {
        let exams = get(item, 'exam_in_vet_cases', [])
        exams.length === 0 && setCanEditHorse(true)
    }, [item])


    const defaultValues = {
        case_number: get(item, 'case_number', ''),
        horse: get(item, 'horse.@id', ''),
        reason: get(item, 'reason', ''),
        notes: get(item, 'notes', ''),
    };

    const methods = useForm({
        resolver: yupResolver(vetSchema),
        defaultValues,
    });

    const { userInOrganisation } = useSelector((state) => state.user);

    const query = useQueryClient()

    let org_id = get(userInOrganisation, 'organisation.id', null)


    const horsesData = query.getQueryData(['horses', org_id])

    let temp = get(horsesData, 'data.hydra:member', [])
    const _horses = isEmpty(temp) ? [] : [...temp].sort((a, b) => a?.name?.localeCompare(b?.name))

    const onSubmit = (data,a) => {
        if(!canEditHorse) {
            unset(data,'horse')
        }
        onUpdate(data,item.id)
    };


    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = methods;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <Edit color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>{translate('modal.updateCase')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Reason */}

                {/* End Header */}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} marginTop={3}>

                        {!canEditHorse && <Grid item md={12} sm={12}>
                            <Alert severity="info">{translate('modal.editCaseHint')}</Alert>
                        </Grid>}

                        <Grid item md={12} sm={12}>
                            <FormControl fullWidth error={errors?.case_number}>
                                <TextField
                                    {...register("case_number")}
                                    error={errors?.case_number}
                                    name='case_number' label={translate('grid.folderNumber')} placeholder={translate('grid.folderNumber')}></TextField>
                                <FormHelperText>{errors?.case_number?.message}</FormHelperText>

                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <FormControl fullWidth error={errors?.horse}>
                                <InputLabel id="demo-simple-select-label">{translate('grid.horseName')}</InputLabel>
                                <Select
                                    disabled={!canEditHorse}
                                    name='horse'
                                    defaultValue={get(item,'horse.@id',"")}
                                    {...register("horse")}
                                    onChange={(e) => setValue('horse', e.target.value, { shouldValidate: true })} // Using setValue
                                    label="Horse"
                                >
                                    {_horses?.map(el => (
                                        <MenuItem key={el?.external_id} value={get(el, '@id')}>{el?.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{errors?.horse?.message}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField {...register("reason")} name='reason'
                                    placeholder={translate('grid.reason')}
                                    label={translate('grid.reason')}

                                ></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField {...register("notes")} name='notes'
                                    placeholder={translate('grid.note')}
                                    label={translate('grid.note')}
                                ></TextField>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box width='100%' marginTop={5} display='flex' justifyContent='end' alignItems='center' gap={2}>
                        <Button disabled={loading} onClick={onCancel} variant='outlined'>{translate('nav.cancel')}</Button>
                        <LoadingButton type='submit' loading={loading}  color='primary' variant='contained'>{translate('gridOptions.update')}</LoadingButton>
                    </Box>
                </form>

                {/* Footer */}

            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};

export default UpdateCaseModal