import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash'
import DatagridOptions from 'src/components/dashboard/DatagridOptions';
import { gridKeys } from 'src/enums/dashboard.enums';
import { formatDate, getOutils, getOutilsColor } from './dashboard';
import { ExamExtractor } from './ExamExtractor';


//Uncategorized Exams
export const dateComparator = (date1, date2) => {
    return dayjs(date1, "DD/MM/YYYY HH:mm").isAfter(dayjs(date2, "DD/MM/YYYY HH:mm")) ? 1 : -1
}

export const dateComparatorDesc = (date1, date2) => {
    return dayjs(date1, "DD/MM/YYYY HH:mm").isBefore(dayjs(date2, "DD/MM/YYYY HH:mm")) ? 1 : -1
}

export const uncategorizedExamsColumns = [
    { field: gridKeys.horse, headerName: gridKeys.horse, flex: 1, valueGetter: (params) => ExamExtractor.getHorseName(params.row), sortable: false, filterable: false },
    { field: gridKeys.client, headerName: gridKeys.client, flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },
    {
        field: gridKeys.tool, headerName: gridKeys.lastUsedTool, flex: 1, renderCell: (params) => {
            let outil = getOutils(get(params, 'row.@type', null))
            let colorOutils = getOutilsColor(get(params, 'row.@type', null))
            return <Chip label={outil} sx={{ backgroundColor: colorOutils.background, color: colorOutils.text }} />
        }, sortable: false, filterable: false
    },
    { field: gridKeys.description, headerName: gridKeys.description, flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },

    { field: gridKeys.creation, headerName: gridKeys.creation, flex: 1, type: 'date', sortable: false, filterable: false, valueGetter: (params) => ExamExtractor.getLastExam(params.row) },
    { field: gridKeys.actions, headerName: gridKeys.actions, width: 200, renderCell: (params) => <DatagridOptions inlineOptions isExam isVetCase={false} item={params.row} />, sortable: false, filterable: false }
];

//Filter fields for vet cases
export const uncategorizedExamsFilters = [
    { field: gridKeys.horse, headerName: gridKeys.horse, data: [], value: '', path: 'horse.name' },
    { field: gridKeys.client, headerName: gridKeys.client, data: [], value: '', path: 'horse.owner' },
    { field: gridKeys.tool, headerName: gridKeys.tool, data: [], value: '', path: '@type' },
    { field: gridKeys.creation, headerName: gridKeys.date, value: '', path: 'timestamp_start.unix', hidden: true },
];



//Dashboard vet cases exams view


export const vetCaseExamsColumns = [
    { field: gridKeys.creation, headerName: gridKeys.creation, type: 'date', sortComparator: dateComparator, flex: 1, valueGetter: (params) => ExamExtractor.getLastExam(params.row), sortable: false, filterable: false },
    { field: gridKeys.description, headerName: gridKeys.description, flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },
    {
        field: gridKeys.tool, headerName: gridKeys.tool, flex: 1, renderCell: (params) => {
            let outil = getOutils(get(params, 'row.@type', null))
            let colorOutils = getOutilsColor(get(params, 'row.@type', null))
            return <Chip label={outil} sx={{ backgroundColor: colorOutils.background, color: colorOutils.text }} />
        }, sortable: false, filterable: false
    },
    { field: gridKeys.client, headerName: gridKeys.client, flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },


    { field: gridKeys.actions, headerName: gridKeys.actions, renderCell: (params) => <DatagridOptions isExam isVetCase={false} item={params.row} />, sortable: false, filterable: false }
];

//Filter fields for vet cases
export const vetCaseExamsFilters = [
    { field: gridKeys.client, headerName: gridKeys.client, data: [], value: '', path: 'horse.owner' },
    { field: gridKeys.tool, headerName: gridKeys.tool, data: [], value: '', path: '@type' },
    { field: gridKeys.creation, headerName: gridKeys.date, value: '', path: 'timestamp_start.unix', hidden: true },
];