import { paramCase } from "change-case";
import { useState, useEffect, useMemo } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  IconButton,
  TableContainer,
  TablePagination,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// hooks
import useLocales from "../../hooks/useLocales";
import useTabs from "../../hooks/useTabs";
import useTable, { getComparator, emptyRows } from "../../hooks/useTable";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { declineUserInOrganisations, getOrganisation, hasError, updateUser, updateUserInOrganisation } from "../../redux/slices/user";
// components
import Iconify from "../../components/Iconify";
import Scrollbar from "../../components/Scrollbar";
import TableHeader from "../../components/TableHeader";
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from "../../components/table";
import { DialogAnimate } from "../../components/animate";
// sections
import { OrganisationMemberTableToolbar, OrganisationMemberTableRow } from ".";
import { UserInvitationForm } from "../user";
import UpdateUserModal from "src/components/dashboard/UpdateUserModal";
import { useMutation } from "react-query";
import { get, isEmpty, isNaN } from "lodash";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ["all"];

const TABLE_HEAD = [
  { id: "name", label: "Name", align: "left" },
  { id: "teams", label: "Teams", align: "left" },
  { id: "role", label: "Role", align: "center" },
  { id: "activated", label: "Status", align: "center" },
  { id: "" },
];

// ----------------------------------------------------------------------

export default function OrganisationMembers() {
  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { organisation, userInOrganisation, error } = useSelector((state) => state.user);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [selectedItem, setSelectedItem] = useState(null)
  const [openUpdateModal, setOpenUpdateModal] = useState(false)
  const updateUserMutation = useMutation(({ id, type }) => updateUserInOrganisation(id, type))
  const { enqueueSnackbar } = useSnackbar()

  const ORG_ID = useMemo(() => {
    return !get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id
  }, [userInOrganisation])

  useEffect(() => {
    dispatch(getOrganisation(ORG_ID));
  }, [dispatch]);

  useEffect(() => {
    if (organisation && organisation.user_in_organisations.length) {
      setTableData(organisation.user_in_organisations);
    }
  }, [organisation]);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } =
    useTabs("all");

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  useEffect(() => {
    if (!isEmpty(error)) {
      enqueueSnackbar(translate('general.error'), {
        variant: 'error'
      })
      dispatch(hasError(null))
    }
  }, [error])

  const deleteUserInOrgMutation = useMutation((id) => dispatch(declineUserInOrganisations(id)))
  const handleDeleteRow = (id) => {
    deleteUserInOrgMutation.mutateAsync(id)
      .then(() => {
        const deleteRow = tableData.filter((row) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);
      }).catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
  };

  const handleDeleteRows = (selected) => {
    const deleteRows = tableData.filter((row) => !selected.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);
  };

  const handleEditRow = (row) => {
    setSelectedItem(row)
    setOpenUpdateModal(true)
  };

  const editUser = (type) => {
    let data = {
      id: get(selectedItem, 'id'),
      type
    }
    updateUserMutation.mutateAsync(data)
      .then(({ data }) => {
        dispatch(getOrganisation(ORG_ID))
        enqueueSnackbar(translate('snackbar.updateSuccess'), {
          variant: 'success'
        })
        setOpenUpdateModal(false)
      }).catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })

  }

  const handleInviteMember = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
  });

  const denseHeight = 52;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus);

  const actionStack = (
    <Stack spacing={2} direction="row">
      <Button
        variant="contained"
        onClick={handleInviteMember}
        sx={{ textTransform: "none" }}
        startIcon={<Iconify icon={"bxs:user-plus"} />}
      >
        {translate("nav.inviteMember")}
      </Button>
    </Stack>
  );

  const organisationLimitedMembersNumber = !isEmpty(organisation?.teams) ? organisation.teams.reduce(
    (previousValue, currentValue, index) =>
      previousValue + currentValue?.limited_access_users?.length,
    0
  ) : 0;

  return (
    <>
      <TableHeader
        heading="Membres de la clinique"
        tags={[
          {
            value: translate("settings.organisationMembersNumber", {
              count: dataFiltered.length,
            }),
            color: "primary",
          },
          {
            value: translate("settings.organisationLimitedMembersNumber", {
              count: isNaN(organisationLimitedMembersNumber) ? 0 : parseInt(organisationLimitedMembersNumber),
            }),
            color: "secondary",
          },
        ]}
        action={actionStack}
      />

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={onChangeFilterStatus}
          sx={{ px: 2, bgcolor: "background.neutral" }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab disableRipple key={tab} label={translate(tab)} value={tab} />
          ))}
        </Tabs>

        <Divider />

        <OrganisationMemberTableToolbar
          filterName={filterName}
          onFilterName={handleFilterName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: "relative" }}>
            {selected.length > 0 && (
              <TableSelectedActions
                dense={true}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                actions={
                  <Tooltip title="Delete">
                    <IconButton
                      color="primary"
                      onClick={() => handleDeleteRows(selected)}
                    >
                      <Iconify icon={"eva:trash-2-outline"} />
                    </IconButton>
                  </Tooltip>
                }
              />
            )}

            <Table size={"small"}>
              <TableHeadCustom
                withCheckbox={false}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <OrganisationMemberTableRow
                      deleteLoading={deleteUserInOrgMutation.isLoading}
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData isNotFound={isNotFound} />
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            labelRowsPerPage={translate("table.rowsperpage")}
          />
        </Box>
      </Card>

      <UpdateUserModal open={openUpdateModal} item={selectedItem} handleClose={() => {
        setSelectedItem(null)
        setOpenUpdateModal(false)
      }} loading={updateUserMutation.isLoading} onSubmit={editUser}></UpdateUserModal>

      <Modal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Typography variant='h6'>{translate("nav.inviteMember")}</Typography>
          <UserInvitationForm onCancel={() => setIsOpenModal(false)} />

        </Box>
      </Modal>

    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterName, filterStatus }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) => {
        console.log({ item });
        let name = get(item, 'user.firstname', "").concat(" " + get(item, 'user.lastname', ""))
        return name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      }
    );
  }

  if (filterStatus !== "all") {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  return tableData;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 1,
  maxHeight:700,
  overflowY:'scroll'
};