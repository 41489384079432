import { get } from "lodash"
import { formatDate, getOutils } from "./dashboard"
import dayjs from "dayjs"

export class ExamExtractor {

    static getExamType(exam) {
        return getOutils(get(exam, '@type'))
    }

    static getHorseName(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return get(exam, 'horse.name', '-')
            case 'Equimetre':
                return get(exam, 'horse_trainer.horse.name', '-')

            default:
                return '-'
        }
    }

    static getHorseId(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return get(exam, 'horse.external_id', '-')
            case 'Equimetre':
                return get(exam, 'horse_trainer.horse.external_id', '-')

            default:
                return '-'
        }
    }
    static getHorse(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return get(exam, 'horse')
            case 'Equimetre':
                return get(exam, 'horse_trainer.horse')

            default:
                return '-'
        }
    }

    static getHorseOwner(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return get(exam, 'horse.owner', '-')
            case 'Equimetre':
                return get(exam, 'horse_trainer.trainer.firstname', '') + ' ' + get(exam, 'horse_trainer.trainer.lastname', '')
            default:
                return '-'
        }
    }

    static getDescription(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return get(exam, 'description', '-')
            case 'Equimetre':
                return get(exam, 'description', '-')

            default:
                return '-'
        }
    }

    static getLastExam(exam) {
        let examType = this.getExamType(exam)
        switch (examType) {
            case 'Equisym':
                return formatDate(get(exam, 'timestamp_start.unix', null))
            case 'Equimetre':
                return dayjs(get(exam, 'creation_date')).format('DD/MM/YYYY HH:mm')

            default:
                return '-'
        }
    }
}
