import { useState, useEffect } from "react";

// @mui
import {
  Typography,
  Stack,
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
// hooks
import useLocales from "../../hooks/useLocales";
import { get, isEmpty, isNull, orderBy } from "lodash";
import { useMemo } from "react";
import { CopyAll, StraightenTwoTone } from "@mui/icons-material";


const MAX_RR_EXCEDENCE_VALUE = 500

export default function EcgToolbar({
  showSpeed,
  handleShowSpeedChange,
  reverseEcg,
  handleReverseEcgChange,
  hrOrRrValue,
  handleHrOrRrValueChange,
  hrOrRrOptions,
  picsOptions,
  onLabelModeChange,
  labelMode,
  ecgLabels,
  onCurrentLabelChanged,
  currentEcgLabel,
  isActionsAllowed,
  takeScreenshot,
  onEditLabelModeChange,
  editLabelMode,
  onPicsChange,
  picOption,
  picsDisabled,
  interPicsChanged,
  timeInterPics,
  isQuadrillageEnabled,
  onQuadrillageChange,
  quadrillageValue,
  onChangeQuadrillageValue,
  arythmiePr,
  onChangeArythmiePr,
  onDetectArythmie,
  arythmies,
  onArythmiesNext,
  onArythmiesPrev,
  isDetectionPicsLaunched,
  r_peak_uri,
  sync,
  onSyncChange,
  isRulerEnabled,
  onRulerChange,
  arythmiesIdx
}) {
  const { translate } = useLocales()
  const [currentLabel, setCurrentLabel] = useState(null)

  const reOrderedLabels = useMemo(() => {
    return orderBy(ecgLabels, ['index_start'], ['asc'])
  }, [ecgLabels])

  const isInputDisabled = picsDisabled || !isDetectionPicsLaunched || !r_peak_uri

  const disabledMessage = () => {
    if (!r_peak_uri) return translate('snackbar.picFileNotFound')
    if (!isDetectionPicsLaunched) return translate('snackbar.pleaseLanchDetection')
    if (picsDisabled) return translate('snackbar.enlargeGraph')
    return ""
  }
  const disabledMessageNoZoom = () => {
    if (!r_peak_uri) return translate('snackbar.picFileNotFound')
    if (!isDetectionPicsLaunched) return translate('snackbar.pleaseLanchDetection')
    return ""
  }

  const handleNextLabel = () => {
    if (!isEmpty(reOrderedLabels)) {
      if (!currentEcgLabel) {
        setCurrentLabel(reOrderedLabels[0])
      } else {
        let curr_index = reOrderedLabels.findIndex(label => label.id === currentEcgLabel.id)
        if (curr_index === ecgLabels.length - 1) {
          setCurrentLabel(reOrderedLabels[0])
        } else {
          setCurrentLabel(reOrderedLabels[curr_index + 1])
        }

      }
    }
  }
  const handlePreviousLabel = () => {
    if (!isEmpty(reOrderedLabels)) {
      if (!currentEcgLabel) {
        setCurrentLabel(reOrderedLabels[0])
      } else {
        let curr_index = reOrderedLabels.findIndex(label => label.id === currentEcgLabel.id)

        if (curr_index === 0) {
          setCurrentLabel(reOrderedLabels[reOrderedLabels.length - 1])
        } else {
          setCurrentLabel(reOrderedLabels[curr_index - 1])
        }

      }
    }
  }
  useEffect(() => {
    currentLabel && onCurrentLabelChanged(currentLabel)
  }, [currentLabel])

  return (
    <Stack spacing={2}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "grid",
            gridAutoColumns: "1fr",
            gap: 2,
          }}
        >
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 2" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('ecg.display.title')}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridAutoFlow: "column",
                gap: 2,
              }}
            >
              <Stack direction="column" spacing={1}>
                <>
                  <Tooltip sx={{ m: 0, p: 0 }} arrow title={disabledMessage()} placement="bottom-start">
                    <FormControlLabel
                      value="interPicsTime"
                      control={<Checkbox  checked={timeInterPics} onChange={e => interPicsChanged(e.target.checked)} />}
                      label={translate('ecg.display.tip')}
                    />
                  </Tooltip>
                </>
                <FormControlLabel
                  value="speed"
                  control={
                    <Checkbox
                      checked={showSpeed}
                      onChange={handleShowSpeedChange}
                    />
                  }
                  label={translate('ecg.display.speed')}
                />
                <FormControlLabel
                  value="inverseECG"
                  control={
                    <Checkbox
                      checked={reverseEcg}
                      onChange={handleReverseEcgChange}
                    />
                  }
                  label={translate('ecg.display.invert')}
                />
                <RadioGroup
                  row
                  sx={{ m: 0 }}
                  value={hrOrRrValue}
                  onChange={handleHrOrRrValueChange}
                >
                  {hrOrRrOptions.map((option, index) => (
                    <div key={option.name}>
                      <FormControlLabel
                        key={option.name}
                        value={option.value}
                        control={<Radio disabled={!r_peak_uri} />}
                        label={option.name}
                        sx={{ m: 0 }}
                      />
                    </div>
                  ))}
                </RadioGroup>
              </Stack>

              <Stack direction="column" spacing={1}>
                <FormControlLabel
                  value="quadrillage"
                  control={<Checkbox  checked={isQuadrillageEnabled} onChange={e => onQuadrillageChange(e.target.checked)} />}
                  label={translate('ecg.display.quadrillage')}
                />
                <TextField
                  id="paperSpeed"
                  label={translate('ecg.display.paper_speed')}
                  fullWidth
                  value={quadrillageValue}
                  type="number"
                  onChange={e => onChangeQuadrillageValue(e.target.value)}
                  size="medium"
                />
                <RadioGroup row>
                  {picsOptions.map((option, index) => (
                    <div key={option.name}>
                      <Tooltip arrow title={disabledMessage()} placement="bottom-start">
                        <FormControlLabel
                          key={option.name}
                          value={option.value}
                          control={<Checkbox  checked={picOption[option.value]} onChange={e => {
                            onPicsChange({ [option.value]: e.target.checked })
                          }} />}
                          label={option.name}

                        />
                      </Tooltip>
                    </div>
                  ))}
                </RadioGroup>
              </Stack>
            </Box>
          </Card>
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 2" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('nav.settings')}
            </Typography>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack direction="column" spacing={1}>

                <Tooltip title={disabledMessageNoZoom()} placement="bottom-start">
                  <TextField
                    disabled={!r_peak_uri || !isDetectionPicsLaunched}
                    id="rrPercentage"
                    label={translate('ecg.display.exceed')}
                    fullWidth
                    size="medium"
                    type="number"
                    value={arythmiePr}
                    onChange={event => {
                      let val = parseInt(event.target.value)
                      return onChangeArythmiePr(val >= MAX_RR_EXCEDENCE_VALUE ? MAX_RR_EXCEDENCE_VALUE : val)
                    }}
                  />
                </Tooltip>

              </Stack>
              <Stack>
                <FormControlLabel
                  value="sync"
                  control={<Checkbox checked={sync} onChange={e => onSyncChange(e.target.checked)} />}
                  label={translate('ecg.display.syncChart')}
                />
              </Stack>

            </Stack>
          </Card>
          <Card sx={{ p: 2, gridRow: "1", gridColumn: "span 1" }}>
            <Typography variant="subtitle1" component="h1" paragraph>
              {translate('ecg.display.export')}
            </Typography>
            <Stack spacing={2} direction="column">
              <Button size="small" onClick={() => takeScreenshot()} variant="contained">{translate('ecg.display.toPng')}</Button>
              <Button size="small" onClick={() => takeScreenshot(true)} startIcon={<CopyAll></CopyAll>} variant="contained">{translate('button.copy')}</Button>
            </Stack>
          </Card>
        </Box>
      </Box>

      <Stack spacing={2} direction="row">
        <Button disableElevation size="medium" sx={{ color: 'white' }} color={labelMode ? 'success' : 'primary'} onClick={() => onLabelModeChange(!labelMode)} variant="contained">{translate('ecg.display.placeLabel')} {labelMode && `(${translate('active')})`} </Button>
        <Button disableElevation size="medium" sx={{ color: 'white' }} color={editLabelMode ? 'success' : 'primary'} onClick={() => onEditLabelModeChange(!editLabelMode)} variant="contained">{translate('ecg.display.editLabel')} {editLabelMode && `(${translate('active')})`}</Button>
        <Button disableElevation size="medium" disabled={!isActionsAllowed || ecgLabels.length === 0} onClick={handlePreviousLabel} variant="outlined">{translate('ecg.display.prevLabel')}</Button>
        <Button disableElevation size="medium" disabled={!isActionsAllowed || ecgLabels.length === 0} onClick={handleNextLabel} variant="outlined">{translate('ecg.display.nextLabel')}</Button>
        <Divider orientation="vertical" flexItem />
        <Tooltip title={disabledMessageNoZoom()} placement="bottom-start">
          <span>
            <Button disableElevation
              disabled={!r_peak_uri || !isDetectionPicsLaunched}
              onClick={onDetectArythmie} size="medium" variant="contained">{translate('ecg.display.launchAr')}</Button>
          </span>
        </Tooltip>
        <Button disableElevation onClick={onArythmiesPrev} disabled={arythmies.length === 0 || arythmiesIdx === 0} size="medium" variant="outlined">{translate('ecg.display.prevAr')}</Button>
        <Button disableElevation onClick={onArythmiesNext} disabled={arythmies.length === 0 || arythmiesIdx === arythmies.length - 1} size="medium" variant="outlined">{translate('ecg.display.nextAr')}</Button>
        <Button disableElevation color={isRulerEnabled ? 'success' : 'primary'} sx={{ color: 'white' }} onClick={() => onRulerChange(!isRulerEnabled)} variant="contained" startIcon={<StraightenTwoTone></StraightenTwoTone>}>{translate('ecg.display.ruler')} {isRulerEnabled && `(${translate('active')})`}</Button>
      </Stack>
    </Stack>
  );
}
