import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
//
import { getAuth, getOrganisation, inviteUser, pushTeam, saveUser, updateStoreFromUser } from "./user";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  createdUserInTeam: null,
  error: null,
  team: null,
  teams: [],
  selectedTeamId: null,
  isOpenModalNewTeam: false,
  isOpenModalEditTeam: false,
  userNotFound: false
};

const slice = createSlice({
  name: "team",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TEAM
    getTeamSuccess(state, action) {
      state.isLoading = false;
      state.team = action.payload;
      const updateTeam = state.teams.map((_team) => {
        if (_team.id === state.team.id) {
          return state.team;
        }
        return _team;
      });

      state.teams = updateTeam;
    },

    // GET TEAMS
    getTeamsSuccess(state, action) {
      state.isLoading = false;
      state.teams = action.payload;
    },

    // CREATE TEAM
    createTeamSuccess(state, action) {
      const newTeam = action.payload;
      state.isLoading = false;
      state.teams = [...state.teams, newTeam];
    },

    // UPDATE TEAM
    updateTeamSuccess(state, action) {
      const team = action.payload;
      const updateTeam = state.teams.map((_team) => {
        if (_team.id === team.id) {
          return team;
        }
        return _team;
      });

      state.isLoading = false;
      state.teams = updateTeam;
    },

    // DELETE TEAM
    deleteTeamSuccess(state, action) {
      const teamId = action.payload;
      const deleteTeam = state.teams.filter((team) => team.id !== teamId);
      state.teams = deleteTeam;
    },

    // SELECT TEAM
    selectTeam(state, action) {
      const teamId = action.payload;
      state.isOpenModalEditTeam = true;
      state.selectedTeamId = teamId;
    },

    // OPEN MODAL
    openModalNewTeam(state) {
      state.isOpenModalNewTeam = true;
    },

    openModalEditTeam(state) {
      state.isOpenModalEditTeam = true;
    },

    // CLOSE MODAL
    closeModalNewTeam(state) {
      state.isOpenModalNewTeam = false;
      state.selectedTeamId = null;
    },

    closeModalEditTeam(state) {
      state.isOpenModalEditTeam = false;
      state.selectedTeamId = null;
    },

    // CREATE USER IN TEAMS
    createUserInTeamsSuccess(state, action) {
      state.createdUserInTeam = action.payload;
      state.isLoading = false;
    },

    // DELETE USER IN TEAMS
    deleteUserInTeamsSuccess(state, action) {
      state.createdUserInTeam = {};
      state.isLoading = false;
    },

    setUserNotFound(state, action) {
      state.userNotFound = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openModalNewTeam,
  closeModalNewTeam,
  openModalEditTeam,
  closeModalEditTeam,
  selectTeam,
  setUserNotFound,
  getTeamSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getTeams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/teams");
      dispatch(slice.actions.getTeamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function getTeam(id) {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get(`/teams/${id}`);
    dispatch(slice.actions.getTeamSuccess(response.data));
    return response
  } catch (error) {
    dispatch(slice.actions.hasError(error));
    return error
  }
}

// ----------------------------------------------------------------------

export function createTeam(newTeam) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/teams", newTeam);
      dispatch(slice.actions.createTeamSuccess(response.data.team));
      const user = await getAuth()
      dispatch(saveUser(user.data))
      dispatch(updateStoreFromUser(user.data))
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}

// ----------------------------------------------------------------------

export function updateTeam(teamId, updateTeam) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const team = await axios.patch(`/teams/${teamId}`, updateTeam, {
        headers: { "Content-Type": "application/merge-patch+json" },
      });
      dispatch(slice.actions.updateTeamSuccess(team.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTeam(teamId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = await axios.delete(`/teams/${teamId}`);
      dispatch(slice.actions.deleteTeamSuccess(teamId));
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}

// ----------------------------------------------------------------------

export function createUserInTeams(user, team) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/user_in_teams", {
        user: user["@id"],
        team: team["@id"],
      });
      dispatch(slice.actions.createUserInTeamsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUserInTeamsFromEmails(emails, team) {
  return async () => {

    return new Promise(async (resolve, reject) => {
      try {

        emails.forEach(async (email) => {

          const users = await axios.get(`/users?email=${email}`);
          const result = users.data["hydra:member"];
          let user = null
          if (result.length === 0) {
            let { data } = await inviteUser(email)
            user = data
          } else {
            user = result[0];
          }
          //Create user_in_teams

          try {
            const response = await axios.post("/user_in_teams", {
              user: user["@id"],
              team: team["@id"],
            });
          //Update team
/*           dispatch(getTeam(team.id));
 */       resolve(result)
          } catch (err) {
            console.log({ err });
            reject('snackbar.userAlreadyExistInTeam')
          }


        });


        // dispatch(slice.actions.createUserInTeamsSuccess(response.data));
      } catch (error) {
        reject(error)
        dispatch(slice.actions.hasError(error));
        return error
      }
    })


  };
}

// ----------------------------------------------------------------------

export function deleteUserInTeams(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/user_in_teams/${id}`);
      dispatch(slice.actions.deleteUserInTeamsSuccess(response.data));
      const user = await getAuth()
      dispatch(saveUser(user.data))
      dispatch(updateStoreFromUser(user.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function acceptUserInTeams(id, organisationId) {
  console.log("REDUX - acceptUserInTeams");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const userInTeams = await axios.patch(
        `/user_in_teams/${id}`,
        { accepted: true },
        { headers: { "Content-Type": "application/merge-patch+json" } }
      );
      dispatch(getOrganisation(organisationId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function declineUserInTeams(id, organisationId) {
  console.log("REDUX - declineUserInTeams");
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const userInTeams = await axios.delete(`/user_in_teams/${id}`);
      dispatch(getOrganisation(organisationId));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SetUserNotFound(payload) {
  dispatch(slice.actions.setUserNotFound(payload))
}


export function addUserInTeam(userId, teamId) {
  return axios.post("/user_in_teams", {
    user: userId,
    team: teamId,
  });
}