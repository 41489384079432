import { Box } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'
import Master from 'src/layouts/dashboard/report/Master'

function Conclustion({ clinique, pageIndex, totalPages,conclusion }) {
    const { translate } = useLocales()
    return (
        <Master
            pageIndex={pageIndex}
            totalPages={totalPages}
            clinique={clinique}>
            <Box p={5}>
                <h3 style={{ color: 'indigo' }}>{translate('repport.table.conclusion')} </h3>
                <p style={{ fontSize: '14px' }}>{conclusion}</p>
            </Box>
        </Master>
    )
}

export default Conclustion