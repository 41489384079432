import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, ListItemText } from "@mui/material";
// hooks
import useLocales from "../../../hooks/useLocales";
// utils
import { fDateTime } from "../../../utils/formatTime";
import useLongPress from "../../../utils/useLongPress";
//
import Iconify from "../../Iconify";
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from "./style";

// ----------------------------------------------------------------------

NavItemExamen.propTypes = {
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  examen: PropTypes.object,
};

export function NavItemExamen({ examen, isCollapse, open = false, onOpen }) {
  const { type, createdAt, conditions } = examen;

  const { translate } = useLocales();

  const isSelected = false;

  const renderContent = (
    <>
      {/* {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>} */}
      <ListItemTextStyle
        disableTypography
        // primary={translate(type)}
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {fDateTime(createdAt)} {type}
          {conditions && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (conditions) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={isSelected}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle component={RouterLink} to={""} activeRoot={isSelected}>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

NavItemCondition.propTypes = {
  condition: PropTypes.object,
  handleClick: PropTypes.func,
  handleLongPress: PropTypes.func,
};

export function NavItemCondition({
  condition,
  active = false,
  handleClick,
  handleLongPress,
}) {
  const { uuid, timestamp_start } = condition;

  const { translate } = useLocales();

  const onLongPress = () => {
    handleLongPress(uuid);
  };

  const onClick = () => {
    handleClick(uuid);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const createdAt = new Date(timestamp_start.unix * 1000);

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography />
      {fDateTime(createdAt)}
    </>
  );

  return (
    <ListItemStyle {...longPressEvent} id={uuid} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(2)",
            bgcolor: "primary.main",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
