import { Button, Divider, Stack } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import useLocales from 'src/hooks/useLocales';
import { isEmpty } from 'lodash';

function RegisterProvider({withDivider,data,handleClick}) {
  
  const {translate} = useLocales()

  return (
    <Stack spacing={1} marginTop={1} direction='column'>
        {withDivider && <Divider>or</Divider>}  
        {!isEmpty(data) && data.map(el=>(
        <Button  key={el.title} fullWidth variant='outlined' onClick={()=>handleClick(el.link)} disabled={el.isDisabled}>{translate(el.title)}</Button>
        ))}
    </Stack>
  )
}
RegisterProvider.defaultProps={
    withDivider:true,
    data:[]
}

RegisterProvider.propTypes ={
    withDivider:PropTypes.bool,
    data:PropTypes.arrayOf(PropTypes.shape({
        title:PropTypes.string.isRequired,
        isDisabled:PropTypes.bool,
        link:PropTypes.string.isRequired,
    }))
}

export default RegisterProvider