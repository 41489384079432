import React from 'react'
import useLocales from 'src/hooks/useLocales'

function Note() {
    const {translate} = useLocales()
    return (
        <div className='wrapper_padding'>
            <p style={{ fontSize: '14px',marginTop:0 }} dangerouslySetInnerHTML={{__html:translate('repport.table.supplementary.note')}}></p>
        </div>
    )
}

export default Note