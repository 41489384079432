import { BookmarkAddRounded, Close, Delete, DeleteForever, Edit, PushPin } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Drawer, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Input, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import { isEmpty, toNumber, uniq } from 'lodash';
import React, { useEffect } from 'react'
import { useState } from 'react';
import InputMask from 'react-input-mask';

import useLocales from 'src/hooks/useLocales';
import { useMutation, useQueryClient } from 'react-query';
import { Ecg } from 'src/classes/Ecg';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

function LabelModal({ open, handleClose, onSubmit, loading, startPoint, endPoint, plotBands, onDelete, onEdit, itemToEdit, handleDelete, examInVetCase }) {
    const { translate } = useLocales()
    //Data
    const [type, setType] = useState('OTHER')
    const [nameLabel, setName] = useState(null)
    const [comment, setComment] = useState(null)
    const [sp, setSp] = useState(null)
    const [ep, setEp] = useState(null)
    //
    const [drawer, setDrawer] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const [selectedPlotBands, setSelectedPlotBands] = useState([])
    const [plots, setPlots] = useState([])
    const saveEcgMutation = useMutation((data) => Ecg.saveEcgLabel(data))
    const updateEcgMutation = useMutation((data) => Ecg.patchEcgLabel(itemToEdit?.id, data))
    useEffect(() => {
        setComment(itemToEdit?.comment)
        setType(itemToEdit?.type ?? 'OTHER')
        setName(itemToEdit?.label)
    }, [itemToEdit])

    const { enqueueSnackbar } = useSnackbar();

    const OPACITY = 0.5

    useEffect(() => {
        setSp(getTime(startPoint))
        setEp(getTime(endPoint))
    }, [startPoint, endPoint])


    useEffect(() => {
        setSelectedPlotBands(plotBands.find(i => i?.id === selectedId));
    }, [selectedId])



    const getTypes = (colorOpacity = OPACITY) => {
        return [
            {
                text: translate('label.type.pathologie'),
                value: 'PATHOLOGY',
                color: `rgba(236, 94, 88,${colorOpacity} )`
            },
            {
                text: translate('label.type.noise'),
                value: 'NOISE',
                color: `rgba(251, 231, 77,${colorOpacity} )`
            },
            {
                text: translate('label.type.other'),
                value: 'OTHER',
                color: `rgba(122, 221, 251,${colorOpacity} )`
            }
        ]
    }

    const getLabelName = () => {
        switch (type) {
            case "NOISE":
                return translate('label.type.noise')
            case "OTHER":
                return nameLabel ?? ""
            case "PATHOLOGY":
                return nameLabel ?? ""
            default:
                return 'Label'
        }
    }

    const resetValues = () => {
        setName(null)
        setComment(null)
        setType('OTHER')
    }
    const query = useQueryClient()


    const createPlotBand = () => {
        let SP = getMs(sp)
        let EP = getMs(ep)
        let data = {
            "index_start": String(SP),
            "index_end": String(EP),
            "label": String(getLabelName()),
            "type": type,
            "comment": comment,
            "exam_in_vet_case": examInVetCase?.rawId
        }
        if (itemToEdit) {
            updateEcgMutation.mutateAsync(data)
                .then(({ data }) => {
                    enqueueSnackbar(translate('snackbar.updateSuccess'), {
                        variant: 'success'
                    })
                    query.refetchQueries(['labelsQuery', examInVetCase?.id])
                    resetValues()
                    onSubmit()
                }).catch(err => {
                    enqueueSnackbar(translate('general.error'), {
                        variant: 'error'
                    })
                })
        } else {
            saveEcgMutation.mutateAsync(data)
                .then(({ data }) => {
                    enqueueSnackbar(translate('snackbar.createSuccess'), {
                        variant: 'success'
                    })
                    query.refetchQueries(['labelsQuery', examInVetCase?.id])
                    resetValues()
                    onSubmit()
                }).catch(err => {
                    enqueueSnackbar(translate('general.error'), {
                        variant: 'error'
                    })
                })
        }



    }

    const getTime = (ms) => {
        return dayjs.duration(ms, 'milliseconds').format('hh:mm:ss.SSS').replace('undefined', '00')
    }

    const getMs = (date) => {
        let split = date.split(':')
        let h = toNumber(split[0])
        let m = toNumber(split[1])
        let s = parseFloat(split[2])


        if (isNaN(h)) {
            h = 0
        }
        if (isNaN(m)) {
            m = 0
        }
        if (isNaN(s)) {
            s = 0
        }
        return dayjs.duration({ hours: h, minutes: m, seconds: s }).asMilliseconds()
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    {/* Header */}
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box display='flex' gap={1} alignItems='center'>
                            <BookmarkAddRounded color='primary' fontSize='small' />
                            <Typography color='primary' variant='h6'>{translate('label.title')}</Typography>
                        </Box>
                        <IconButton onClick={handleClose}>
                            <Close fontSize='small' />
                        </IconButton>
                    </Box>

                    {/* Body */}
                    <Box sx={{ position: 'relative', overflowY: 'scroll', maxHeight: '500px !important' }} >
                        <Grid container spacing={2} marginTop={1}>
                            <Grid item md={6} xs={6}>
                                <FormControl>
                                    <InputMask
                                        mask="99:99:99.999"
                                        placeholder='HH:MM:SS.sss'
                                        defaultValue={sp}
                                        alwaysShowMask
                                        onKeyDown={(e) => e.stopPropagation()}
                                        onChange={(e) => {
                                            setSp(e.target.value)
                                        }}
                                    >
                                        {(inputProps) => <TextField {...inputProps}
                                            label={translate('label.start')}>
                                        </TextField>}
                                    </InputMask>

                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <FormControl>
                                    <InputMask
                                        mask="99:99:99.999"
                                        placeholder='HH:MM:SS.sss'
                                        alwaysShowMask
                                        onKeyDown={(e) => e.stopPropagation()}
                                        defaultValue={ep}
                                        onChange={(e) => {
                                            setEp(e.target.value)
                                        }}
                                    >
                                        {(inputProps) => <TextField {...inputProps}
                                            label={translate('label.end')}>
                                        </TextField>}
                                    </InputMask>
                                </FormControl>
                            </Grid>
                            <Grid item md={12} xs={12} ml={1}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">{translate('label.type.title')}</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={type}
                                        name="radio-buttons-group"
                                        row
                                        onChange={event => setType(event.target.value)}
                                    >
                                        {getTypes(0.8).map(type => (
                                            <FormControlLabel value={type.value} control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: type.color,
                                                },
                                            }} />} label={type.text} key={type.value} />
                                        ))}

                                    </RadioGroup>
                                </FormControl>

                            </Grid>

                            <Grid item md={12} xs={12} ml={1}>

                                {
                                    type === 'PATHOLOGY' && <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">{translate('label.name')}</InputLabel>
                                        <Select
                                            disabled={['NOISE', 'OTHER'].includes(type)}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={nameLabel}
                                            label={translate('label.name')}
                                            onChange={event => setName(event.target.value)}

                                        >
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    type === 'OTHER' && <FormControl fullWidth >
                                        <TextField
                                            value={nameLabel}
                                            label={translate('label.name')}
                                            onChange={event => setName(event.target.value)}
                                        ></TextField>
                                    </FormControl>
                                }

                            </Grid>

                            <Grid item md={12} xs={12} ml={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        label={translate('label.comment')}
                                        value={comment}
                                        multiline
                                        rows={4}
                                        onChange={event => setComment(event.target.value)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={12} xs={12} display='flex' justifyContent='end' flexDirection='row' gap={1}>
                                {itemToEdit && <LoadingButton loading={loading} onClick={() => handleDelete(itemToEdit)} sx={{ marginTop: '0 !important' }} variant='outlined' color='error'>{translate('nav.delete')}</LoadingButton>}
                                <LoadingButton loading={saveEcgMutation.isLoading || updateEcgMutation.isLoading} onClick={createPlotBand} sx={{ marginTop: '0 !important' }} variant='contained'>{translate('nav.save')}</LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Modal >

        </>

    )
}
LabelModal.defaultProps = {
    open: false,
    handleClose: () => { },
    startPoint: 0,
    endPoint: 0,
}
export default LabelModal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};