import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, Checkbox, CircularProgress, Collapse, Container, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { Divider } from 'antd'
import { log } from 'deck.gl'
import { get, isEmpty } from 'lodash'
import React, { memo, useState } from 'react'
import { useMutation } from 'react-query'
import { Condition } from 'src/classes/Condition'
import { Examen } from 'src/classes/Examen'
import useLocales from 'src/hooks/useLocales'
import { formatDate } from 'src/utils/dashboard'
import DataTable from '../DataTable'
import { EqsChart } from 'src/sections/eqs'

function ConditionView({ condition, isSelected, handleInputChange, examen }) {

  //Hooks
  const { translate } = useLocales()
  const conditionMutations = useMutation((data) => Examen.getConditionData(data))

  //Data
  const [open, setOpen] = useState(false)
  const [selectedCondition, setSelectedCondition] = useState(null)
  const [rawCondition, setRawCondition] = useState(null)

  //Const

  const variablesOptions = [
    {
      title: translate('examEnums.variables.amplitude'),
      subtitle: translate('examEnums.variables.amplitudeDesc'),
      value: "up",
    },
    {
      title: translate('examEnums.variables.max'),
      subtitle: translate('examEnums.variables.maxDesc'),
      value: "max",
    },
    {
      title: translate('examEnums.variables.min'),
      subtitle: translate('examEnums.variables.minDesc'),
      value: "min",
    },
  ];


  //fns
  const formatText = (data) => {
    let figure = data?.figure ? translate(`figure.${data?.figure}`).concat(' - ') : ''
    let ground = translate(`ground.${data?.ground}`) || ''

    return figure.concat(ground)
  }

  const viewCondition = (condition) => {
    setOpen(!open)
    if (selectedCondition) return
    //get Condition
    conditionMutations.mutateAsync({ uuid: condition?.uuid, exam_id: examen?.external_id })
      .then(result => {
        let data = get(Object.values(result.data), '[0]', {})
        //Check if Loaded condition has error or not loaded succefully
        if (data.hasOwnProperty('error') && !isEmpty(data?.error)) {
          return 0;
        }
        let formatedCondition = formatConditions([{ ...examen?.conditions.find(e => e?.uuid === condition?.uuid), condition: data }])
        setSelectedCondition(formatedCondition)
        setRawCondition(condition)
      })
  }

  const formatConditions = (temp) => {
    if (isEmpty(temp)) return []
    let conditions = new Condition(temp, false)
    conditions
      .setConditionsColor()
      .setTeteData()
      .setGarrotData()
      .setCroupeData()
    return conditions.getData()
  }

  //Renders
  return (
    <>
      <ListItem >
        <ListItemText primaryTypographyProps={{ fontSize: '14px' }} secondary={formatDate(condition?.timestamp_start?.unix, 'HH:mm')} primary={formatText(condition)}></ListItemText>

        <Tooltip title={translate('gridOptions:view')} arrow>
          <IconButton onClick={(e) => viewCondition(condition, e)} color='primary'>
            {open ? <VisibilityOff></VisibilityOff> : <Visibility></Visibility>}
          </IconButton>
        </Tooltip>


        <ListItemIcon>
          <Checkbox
            onChange={(_, checked) => handleInputChange(condition, checked)}
            defaultChecked={isSelected}></Checkbox>
        </ListItemIcon>

      </ListItem>

      <Collapse
        in={open}
      >
        <Divider></Divider>
        <Container maxWidth='md'>

          {/* Show Loading */}
          {conditionMutations.isLoading ?
            <Box display={'flex'} justifyContent={'center'} mt={5}>
              <CircularProgress size={18}></CircularProgress>
            </Box>
            : <Box>
              <>
                <DataTable seuils={[]} linesMode={'mean'} option={'up'} conditions={selectedCondition}></DataTable>
                <ViewChart selectedCondition={selectedCondition} condition={rawCondition} variablesOptions={variablesOptions}></ViewChart>
              </>
            </Box>
          }
        </Container>

      </Collapse>
    </>
  )
}

const ViewChart = memo(function Chart({ selectedCondition, condition, variablesOptions }) {

  if (isEmpty(selectedCondition) || !condition) return <></>
  return (
    <>
      {selectedCondition?.map((dataSet) => (
        <>
          <EqsChart
            selectedConditions={[condition]}
            isComparaison={false}
            key={dataSet.key}
            dataSet={dataSet.data}
            linesMode={'mean'}
            variablesMode={JSON.stringify(variablesOptions[0])}
            title={dataSet.key}
            fill={true}
            showChartDetails={false}
          />
        </>
      ))}
    </>
  )
})

export default memo(ConditionView)