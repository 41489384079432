export const SEUILS = [
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -51.2,
    "seuilG": -34.4,
    "seuilG_sup": -25.4,
    "seuilD_inf": -1.4,
    "SeuilD": 16.2,
    "seuilD_sup": 28.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -14.4,
    "seuilG": -9.7,
    "seuilG_sup": -6.2,
    "seuilD_inf": 6.4,
    "SeuilD": 10.7,
    "seuilD_sup": 17.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -33,
    "seuilG": -23.2,
    "seuilG_sup": -4.6,
    "seuilD_inf": 6.5,
    "SeuilD": 13.3,
    "seuilD_sup": 24,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -10.2,
    "seuilG": -8.7,
    "seuilG_sup": -5.2,
    "seuilD_inf": -1.4,
    "SeuilD": 5.8,
    "seuilD_sup": 9.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -8,
    "seuilG": -2.9,
    "seuilG_sup": 0,
    "seuilD_inf": 4.2,
    "SeuilD": 6,
    "seuilD_sup": 9.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -9.8,
    "seuilG": -8.7,
    "seuilG_sup": -7.9,
    "seuilD_inf": -0.7,
    "SeuilD": 1.5,
    "seuilD_sup": 2.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 4.9,
    "seuilG": 9.5,
    "seuilG_sup": 18,
    "seuilD_inf": -9.8,
    "SeuilD": -3.6,
    "seuilD_sup": 1.8,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -3.6,
    "seuilG": 1.1,
    "seuilG_sup": 3.5,
    "seuilD_inf": -7.5,
    "SeuilD": -4.4,
    "seuilD_sup": -2.2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -1.7,
    "seuilG": 4.2,
    "seuilG_sup": 6.3,
    "seuilD_inf": 1.3,
    "SeuilD": 3.6,
    "seuilD_sup": 6,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -32.8,
    "seuilG": -23.4,
    "seuilG_sup": -9.3,
    "seuilD_inf": -2.7,
    "SeuilD": 6.2,
    "seuilD_sup": 11.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -21.1,
    "seuilG": -11,
    "seuilG_sup": -5.5,
    "seuilD_inf": 2.8,
    "SeuilD": 6.2,
    "seuilD_sup": 20.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -25.2,
    "seuilG": -11.6,
    "seuilG_sup": 4.9,
    "seuilD_inf": 1.5,
    "SeuilD": 6.8,
    "seuilD_sup": 13.8,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 0.2,
    "seuilG": 5.9,
    "seuilG_sup": 10.2,
    "seuilD_inf": -16.4,
    "SeuilD": -10.5,
    "seuilD_sup": -6.4,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 0.3,
    "seuilG": 4.9,
    "seuilG_sup": 8.2,
    "seuilD_inf": -1.6,
    "SeuilD": 0.5,
    "seuilD_sup": 4.4,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -1.2,
    "seuilG": 1.6,
    "seuilG_sup": 4.7,
    "seuilD_inf": -8.2,
    "SeuilD": -5,
    "seuilD_sup": -1.8,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -13,
    "seuilG": -10.7,
    "seuilG_sup": -6.6,
    "seuilD_inf": 14.7,
    "SeuilD": 17.7,
    "seuilD_sup": 23,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -15.2,
    "seuilG": -8.8,
    "seuilG_sup": -0.3,
    "seuilD_inf": 5.9,
    "SeuilD": 8,
    "seuilD_sup": 11.5,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "LDD",
    "Figure": 1,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -15,
    "seuilG": -5.1,
    "seuilG_sup": -0.9,
    "seuilD_inf": 10.2,
    "SeuilD": 12.9,
    "seuilD_sup": 17.1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -14.2,
    "seuilG": 6.1,
    "seuilG_sup": 28.3,
    "seuilD_inf": 38.1,
    "SeuilD": 48.9,
    "seuilD_sup": 66.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -20.5,
    "seuilG": -0.1,
    "seuilG_sup": 9.3,
    "seuilD_inf": 4.6,
    "SeuilD": 18.9,
    "seuilD_sup": 34.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -17.6,
    "seuilG": -7.9,
    "seuilG_sup": 4.5,
    "seuilD_inf": 18.3,
    "SeuilD": 25.8,
    "seuilD_sup": 34.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -22.3,
    "seuilG": -11.5,
    "seuilG_sup": -4.3,
    "seuilD_inf": -2.5,
    "SeuilD": 4.9,
    "seuilD_sup": 7.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -26.7,
    "seuilG": -21.9,
    "seuilG_sup": -18.6,
    "seuilD_inf": -15.3,
    "SeuilD": -13.2,
    "seuilD_sup": -9.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": 6,
    "seuilG": 10.7,
    "seuilG_sup": 15,
    "seuilD_inf": 17.9,
    "SeuilD": 23,
    "seuilD_sup": 28.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 8.1,
    "seuilG": 16,
    "seuilG_sup": 22.8,
    "seuilD_inf": 9.5,
    "SeuilD": 14.7,
    "seuilD_sup": 25.5,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -3.6,
    "seuilG": 1,
    "seuilG_sup": 3.4,
    "seuilD_inf": -9.6,
    "SeuilD": -5,
    "seuilD_sup": -2.1,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": 2.7,
    "seuilG": 11.7,
    "seuilG_sup": 14.7,
    "seuilD_inf": 4.1,
    "SeuilD": 11.7,
    "seuilD_sup": 15.7,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -41.9,
    "seuilG": -1.7,
    "seuilG_sup": 19.2,
    "seuilD_inf": 7.1,
    "SeuilD": 28.6,
    "seuilD_sup": 39.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": 8.8,
    "seuilG": 16.2,
    "seuilG_sup": 27,
    "seuilD_inf": 24.1,
    "SeuilD": 29.6,
    "seuilD_sup": 53.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -43.7,
    "seuilG": -1.7,
    "seuilG_sup": 27.9,
    "seuilD_inf": -8,
    "SeuilD": 10.7,
    "seuilD_sup": 19.4,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 5.3,
    "seuilG": 11,
    "seuilG_sup": 18.6,
    "seuilD_inf": -11,
    "SeuilD": -4.1,
    "seuilD_sup": 7.2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": -24,
    "seuilG": -17.1,
    "seuilG_sup": -12.3,
    "seuilD_inf": -20,
    "SeuilD": -16.6,
    "seuilD_sup": -10.3,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": 18.4,
    "seuilG": 22.7,
    "seuilG_sup": 26.8,
    "seuilD_inf": 2.9,
    "SeuilD": 10.6,
    "seuilD_sup": 14.7,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -4.3,
    "seuilG": 1.9,
    "seuilG_sup": 9.7,
    "seuilD_inf": 18.6,
    "SeuilD": 27.8,
    "seuilD_sup": 35.1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -17.4,
    "seuilG": -9.7,
    "seuilG_sup": -5.4,
    "seuilD_inf": 2.7,
    "SeuilD": 6,
    "seuilD_sup": 9.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDD",
    "Figure": 7,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": 4.2,
    "seuilG": 9.8,
    "seuilG_sup": 16.4,
    "seuilD_inf": 14.4,
    "SeuilD": 20.7,
    "seuilD_sup": 23.8,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -68.5,
    "seuilG": -49.2,
    "seuilG_sup": -36.1,
    "seuilD_inf": -43.6,
    "SeuilD": -15.2,
    "seuilD_sup": 1.4,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -23.7,
    "seuilG": -17.2,
    "seuilG_sup": -10.6,
    "seuilD_inf": -11.8,
    "SeuilD": -4.7,
    "seuilD_sup": 1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -35,
    "seuilG": -27.1,
    "seuilG_sup": -19.5,
    "seuilD_inf": -3.2,
    "SeuilD": 7.6,
    "seuilD_sup": 28.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -17,
    "seuilG": -12,
    "seuilG_sup": -9.2,
    "seuilD_inf": 8.5,
    "SeuilD": 12.7,
    "seuilD_sup": 18.8,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -4,
    "seuilG": 6.3,
    "seuilG_sup": 10,
    "seuilD_inf": 13.8,
    "SeuilD": 18.8,
    "seuilD_sup": 24.4,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -26.7,
    "seuilG": -22.4,
    "seuilG_sup": -17.7,
    "seuilD_inf": -13.5,
    "SeuilD": -10.8,
    "seuilD_sup": -7.8,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": -10.6,
    "seuilG": -4.5,
    "seuilG_sup": 2.5,
    "seuilD_inf": -16.2,
    "SeuilD": -8.4,
    "seuilD_sup": -1.4,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": 2.5,
    "seuilG": 5.1,
    "seuilG_sup": 7.4,
    "seuilD_inf": -6.4,
    "SeuilD": -2.5,
    "seuilD_sup": -0.2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -19.8,
    "seuilG": -11.3,
    "seuilG_sup": -7.9,
    "seuilD_inf": -19,
    "SeuilD": -11.3,
    "seuilD_sup": -7.8,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -47.4,
    "seuilG": -43.4,
    "seuilG_sup": -35.9,
    "seuilD_inf": -19.2,
    "SeuilD": -9.4,
    "seuilD_sup": 9.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -19,
    "seuilG": -13.3,
    "seuilG_sup": -1.5,
    "seuilD_inf": -14.7,
    "SeuilD": -5.8,
    "seuilD_sup": 10.4,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -37,
    "seuilG": -26.4,
    "seuilG_sup": -15.5,
    "seuilD_inf": -22.4,
    "SeuilD": -11.7,
    "seuilD_sup": 0.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 5.5,
    "seuilG": 13.8,
    "seuilG_sup": 28.6,
    "seuilD_inf": -29.7,
    "SeuilD": -12,
    "seuilD_sup": -5,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 17.6,
    "seuilG": 21,
    "seuilG_sup": 29.1,
    "seuilD_inf": 4.3,
    "SeuilD": 11.3,
    "seuilD_sup": 16.4,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -14.7,
    "seuilG": -11.8,
    "seuilG_sup": -10.4,
    "seuilD_inf": -33.2,
    "SeuilD": -20.7,
    "seuilD_sup": -10.7,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -25.5,
    "seuilG": -15.7,
    "seuilG_sup": -0.2,
    "seuilD_inf": -4,
    "SeuilD": 7.3,
    "seuilD_sup": 13.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -7.3,
    "seuilG": -1.9,
    "seuilG_sup": 9.2,
    "seuilD_inf": 9.4,
    "SeuilD": 11.5,
    "seuilD_sup": 13.1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGD",
    "Figure": 6,
    "Sol": 1,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -17.1,
    "seuilG": -13.6,
    "seuilG_sup": -7.5,
    "seuilD_inf": -5.8,
    "SeuilD": 0.1,
    "seuilD_sup": 5.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -45.2,
    "seuilG": -34.3,
    "seuilG_sup": -24.8,
    "seuilD_inf": 12.2,
    "SeuilD": 18.1,
    "seuilD_sup": 23.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -2.1,
    "seuilG": 4.5,
    "seuilG_sup": 10.5,
    "seuilD_inf": 16.9,
    "SeuilD": 20.6,
    "seuilD_sup": 26.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -56.8,
    "seuilG": -39.1,
    "seuilG_sup": -25.3,
    "seuilD_inf": -4.8,
    "SeuilD": 1.9,
    "seuilD_sup": 11.6,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -9.8,
    "seuilG": -0.9,
    "seuilG_sup": 4.2,
    "seuilD_inf": 11.9,
    "SeuilD": 16.4,
    "seuilD_sup": 18.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": -23.1,
    "seuilG": -18.5,
    "seuilG_sup": -12.6,
    "seuilD_inf": -14.2,
    "SeuilD": -10.7,
    "seuilD_sup": -8.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": 16,
    "seuilG": 20.4,
    "seuilG_sup": 30.7,
    "seuilD_inf": 27.2,
    "SeuilD": 31.5,
    "seuilD_sup": 38.1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": 12.7,
    "seuilG": 19.1,
    "seuilG_sup": 24.2,
    "seuilD_inf": 8,
    "SeuilD": 12.3,
    "seuilD_sup": 17.5,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": -4.7,
    "seuilG": -2,
    "seuilG_sup": -0.1,
    "seuilD_inf": -9.6,
    "SeuilD": -6.1,
    "seuilD_sup": -2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": 15.9,
    "seuilG": 20.8,
    "seuilG_sup": 23.6,
    "seuilD_inf": 15.5,
    "SeuilD": 20.1,
    "seuilD_sup": 25.1,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -14.4,
    "seuilG": 0.6,
    "seuilG_sup": 27,
    "seuilD_inf": 4.8,
    "SeuilD": 18.2,
    "seuilD_sup": 39.5,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": 9.5,
    "seuilG": 20.7,
    "seuilG_sup": 46.9,
    "seuilD_inf": 8.2,
    "SeuilD": 14.9,
    "seuilD_sup": 22.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -24.8,
    "seuilG": -7.1,
    "seuilG_sup": 31.6,
    "seuilD_inf": -3,
    "SeuilD": 7.7,
    "seuilD_sup": 37,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": 12.9,
    "seuilG": 16,
    "seuilG_sup": 19.2,
    "seuilD_inf": -1.6,
    "SeuilD": 4.8,
    "seuilD_sup": 13.1,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": -15.5,
    "seuilG": -10.7,
    "seuilG_sup": -6.6,
    "seuilD_inf": -24.4,
    "SeuilD": -17.4,
    "seuilD_sup": -14.2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": 22.4,
    "seuilG": 26.4,
    "seuilG_sup": 29.7,
    "seuilD_inf": 5.2,
    "SeuilD": 15.9,
    "seuilD_sup": 20.4,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -9.8,
    "seuilG": -0.6,
    "seuilG_sup": 8.4,
    "seuilD_inf": 25.1,
    "SeuilD": 33.2,
    "seuilD_sup": 42.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -15.6,
    "seuilG": -12.1,
    "seuilG_sup": -7.2,
    "seuilD_inf": -0.6,
    "SeuilD": 1.4,
    "seuilD_sup": 3.1,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MDS",
    "Figure": 7,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -0.1,
    "seuilG": 10.5,
    "seuilG_sup": 23.7,
    "seuilD_inf": 17.2,
    "SeuilD": 24.8,
    "seuilD_sup": 30.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.up",
    "seuilG_inf": -37.4,
    "seuilG": -24.5,
    "seuilG_sup": -14.7,
    "seuilD_inf": -5.2,
    "SeuilD": 19.2,
    "seuilD_sup": 33.5,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.max",
    "seuilG_inf": -26,
    "seuilG": -16.3,
    "seuilG_sup": -8,
    "seuilD_inf": -6.2,
    "SeuilD": -0.3,
    "seuilD_sup": 4.4,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "headSI.min",
    "seuilG_inf": -17.2,
    "seuilG": -8.6,
    "seuilG_sup": 1.8,
    "seuilD_inf": 21.4,
    "SeuilD": 30.4,
    "seuilD_sup": 44.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.up",
    "seuilG_inf": -27,
    "seuilG": -19.5,
    "seuilG_sup": -14.6,
    "seuilD_inf": -6.5,
    "SeuilD": 0.8,
    "seuilD_sup": 7.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.max",
    "seuilG_inf": 1.7,
    "seuilG": 8,
    "seuilG_sup": 11.3,
    "seuilD_inf": 6,
    "SeuilD": 15.3,
    "seuilD_sup": 19.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "withersSI.min",
    "seuilG_inf": -31,
    "seuilG": -26.3,
    "seuilG_sup": -20,
    "seuilD_inf": -18.1,
    "SeuilD": -14.8,
    "seuilD_sup": -9.3,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.up",
    "seuilG_inf": -6.1,
    "seuilG": -2.6,
    "seuilG_sup": 3.6,
    "seuilD_inf": -32.1,
    "SeuilD": -18.8,
    "seuilD_sup": -14,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.max",
    "seuilG_inf": 3.6,
    "seuilG": 6.8,
    "seuilG_sup": 10.9,
    "seuilD_inf": -1.9,
    "SeuilD": -0.2,
    "seuilD_sup": 1.2,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "ANT",
    "Indice": "croupSI.min",
    "seuilG_inf": -14.8,
    "seuilG": -11,
    "seuilG_sup": -3.9,
    "seuilD_inf": -21.4,
    "SeuilD": -17.7,
    "seuilD_sup": -14.9,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.up",
    "seuilG_inf": -67.5,
    "seuilG": -25.2,
    "seuilG_sup": -14.6,
    "seuilD_inf": -7.8,
    "SeuilD": 8.4,
    "seuilD_sup": 22.5,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.max",
    "seuilG_inf": -24,
    "seuilG": -11.4,
    "seuilG_sup": -2.4,
    "seuilD_inf": -32.6,
    "SeuilD": -14.8,
    "seuilD_sup": -4.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "headSI.min",
    "seuilG_inf": -28.1,
    "seuilG": -4.4,
    "seuilG_sup": 15,
    "seuilD_inf": -3.4,
    "SeuilD": 17.8,
    "seuilD_sup": 30.2,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.up",
    "seuilG_inf": -17.5,
    "seuilG": -4.5,
    "seuilG_sup": 0.8,
    "seuilD_inf": -20.3,
    "SeuilD": -13.3,
    "seuilD_sup": -8.6,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.max",
    "seuilG_inf": 8.1,
    "seuilG": 15.9,
    "seuilG_sup": 21.4,
    "seuilD_inf": 6,
    "SeuilD": 10.5,
    "seuilD_sup": 14.3,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "withersSI.min",
    "seuilG_inf": -20.1,
    "seuilG": -16.1,
    "seuilG_sup": -10.8,
    "seuilD_inf": -23.9,
    "SeuilD": -19.9,
    "seuilD_sup": -11.1,
    "Actif": 0
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.up",
    "seuilG_inf": -22.9,
    "seuilG": -20.8,
    "seuilG_sup": -16.6,
    "seuilD_inf": -2.5,
    "SeuilD": 1.2,
    "seuilD_sup": 5.7,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.max",
    "seuilG_inf": -6.4,
    "seuilG": -3.8,
    "seuilG_sup": -0.6,
    "seuilD_inf": -1.4,
    "SeuilD": 8.4,
    "seuilD_sup": 11.9,
    "Actif": 1
  },
  {
    "Version": "2022_12_02",
    "Condition": "MGS",
    "Figure": 6,
    "Sol": 2,
    "Type_seuil": "POST",
    "Indice": "croupSI.min",
    "seuilG_inf": -35.9,
    "seuilG": -25.3,
    "seuilG_sup": -19.6,
    "seuilD_inf": -20.6,
    "SeuilD": -12.7,
    "seuilD_sup": -8.5,
    "Actif": 1
  }
]