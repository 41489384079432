import React from 'react'
import useLocales from 'src/hooks/useLocales';

import { EqsGraphView } from 'src/sections/eqs'




function Graph({ results, formatedConditions  }) {
    const { translate } = useLocales()
    //Constants
    const variablesOptions = [
        {
            title: translate('examEnums.variables.amplitude'),
            subtitle: translate('examEnums.variables.amplitudeDesc'),
            value: "up",
        },
        {
            title: translate('examEnums.variables.max'),
            subtitle: translate('examEnums.variables.maxDesc'),
            value: "max",
        },
        {
            title: translate('examEnums.variables.min'),
            subtitle: translate('examEnums.variables.minDesc'),
            value: "min",
        },
    ];

    return (
        <div >
            <EqsGraphView
                showChartDetails={false}
                forPrint
                nbFoulee={27}
                rawConditions={results}
                results={formatedConditions}
                isComparaison={false}
                variablesOptions={variablesOptions}
                linesMode={'mean'}
                variablesMode={JSON.stringify(variablesOptions[0])}
                fluidMode
            >

            </EqsGraphView>
        </div>
    )
}

export default Graph