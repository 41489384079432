import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { get } from 'lodash'
import React from 'react'
import TableMatiers from 'src/components/report/TableMatiers'
import useLocales from 'src/hooks/useLocales'
import Master from 'src/layouts/dashboard/report/Master'
import { formatDate } from 'src/utils/dashboard'



function Cover({ vetCase, report, pages, clinique, pageIndex, totalPages,examen }) {

    const { translate } = useLocales()

    const getReportType = () => {
        if (!report) return ''

        let str = ''
        let type = get(report, 'type')
        let creation_date = get(examen, 'timestamp_start.unix')

        if (type) {
            str += translate('examType.' + type)
        }

        if (creation_date) {
            return str +=` ${translate('of')} ${formatDate(creation_date,'DD/MM/YYYY')} `
        }

        return str


    }

    return (
        <Master
            pageIndex={pageIndex}
            totalPages={totalPages}
            clinique={clinique}>
            <Box width={'50%'} margin={'auto'} display={'flex'} flexDirection={'column'} gap={4} textAlign={'center'} mt={5} >
                <h1 style={{ color: 'indigo' }}>{translate('repport.table.supplementary.titleHeader')}</h1>
                <h1 style={{ color: 'indigo', fontWeight: "normal", fontSize: '18px' }}>{translate('horse.horse')} : {get(vetCase, 'horse.name')}</h1>
                <h1 style={{ color: 'indigo', fontWeight: "normal", fontSize: '18px' }}>{get(vetCase, 'team.organisation.name')}</h1>
                <h1 style={{ color: 'indigo', fontWeight: "normal", fontSize: '18px' }}>{getReportType()}</h1>
            </Box>
            <TableMatiers pages={pages}></TableMatiers>
        </Master>
    )
}

export default Cover