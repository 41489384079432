import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import teamReducer from "./slices/team";
import horseReducer from "./slices/horse";
import examenReducer from "./slices/examen";
import displayReducer from "./slices/display";

// ------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const userPersistConfig = {
  key: "user",
  storage,
  keyPrefix: "redux-",
  whitelist: [""],
};

const teamPersistConfig = {
  key: "team",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const horsePersistConfig = {
  key: "horse",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};

const examenPersistConfig = {
  key: "examen",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};
const displayPersistConfig = {
  key: "display",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy"],
};
const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  team: persistReducer(teamPersistConfig, teamReducer),
  horse: persistReducer(horsePersistConfig, horseReducer),
  examen: persistReducer(examenPersistConfig, examenReducer),
  display: persistReducer(displayPersistConfig, displayReducer),
});

export { rootPersistConfig, rootReducer };
