import { Box, CircularProgress, Container, Step, StepButton, StepContent, Stepper } from '@mui/material'
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ConditionsSelector from 'src/components/report/newReport/ConditionsSelector';
import { LocomotionType } from 'src/enums/dashboard.enums';
import useLocales from 'src/hooks/useLocales';
import { getExamen } from 'src/redux/slices/examen';
import axiosInstance from 'src/utils/axios';

function CreateNewReport() {
    //State
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [conditions, setConditions] = useState([])

    //Stepper data
    const [Ld, setld] = useState([])
    const [LdComment, setldComment] = useState([])
    const [LdFa, setldFa] = useState([])
    const [LdFaComment, setldFaComment] = useState([])
    const [LdFp, setldFp] = useState([])
    const [LdFpComment, setldFpComment] = useState([])
    const [Lc, setLc] = useState([])
    const [LcComment, setLcComment] = useState([])
    const [Rc, setRc] = useState([])
    const [RcComment, setRcComment] = useState([])
    const [conclusion, setConclusion] = useState('')



    //Hooks
    const { examen, isLoading, error } = useSelector((state) => state.examen);
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const createReprotMutation = useMutation((data) => axiosInstance.post('/reports', data))
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()

    //Consts
    const ID = searchParams.get('exam')
    const VET_ID = searchParams.get('vetCase')

    //Effects
    useEffect(() => {
        if (!isEmpty(ID)) {
            dispatch(getExamen(ID))
        }
    }, [ID])

    useEffect(() => {
        if (examen) {
            setConditions(examen.conditions)

        }
    }, [examen])

    //Memo 

    //Condition Ligne droite
    const LdConditions = useMemo(() => {
        if (isEmpty(conditions)) return []
        return conditions.filter(e => e?.figure === 'STRAIGHT_LINE' && e?.gait === 'TROT')
    }, [conditions])

    console.log({ conditions });

    //Left circle conditions
    const LcConditions = useMemo(() => {
        if (isEmpty(conditions)) return []
        return conditions.filter(e => e?.figure === 'LEFT_CIRCLE' && e?.gait === 'TROT')
    }, [conditions])


    //right circle conditions
    const RcConditions = useMemo(() => {
        if (isEmpty(conditions)) return []
        return conditions.filter(e => e?.figure === 'RIGHT_CIRCLE' && e?.gait === 'TROT')
    }, [conditions])

    //ligne droite et flexion anterieur conditions
    const LdFAConditions = useMemo(() => {
        if (isEmpty(conditions)) return []
        return conditions.filter(e => ['SL_LEFT_FORELIMB_FLEX', 'SL_RIGHT_FORELIMB_FLEX'].includes(e?.figure) && e?.gait === 'TROT')
    }, [conditions])

    //ligne droite et flexion anterieur conditions
    const LdFPConditions = useMemo(() => {
        if (isEmpty(conditions)) return []
        return conditions.filter(e => ['SL_LEFT_HINDLIMB_FLEX', 'SL_RIGHT_HINDLIMB_FLEX'].includes(e?.figure) && e?.gait === 'TROT')
    }, [conditions])

    const getTitle = (selected = [], titles = []) => {
        //Titles array of 3 string first one soft and har
        //Second string for SOFT
        //third string for HARD
        if (isEmpty(selected)) return titles[0]

        let soft = selected.find(e => e?.ground === 'SOFT')
        let hard = selected.find(e => e?.ground === 'HARD')

        if (soft && hard) return titles[0]
        if (soft && !hard) return titles[1]
        if (!soft && hard) return titles[2]

    }

    //Const 
    const steps = [
        {
            label: 'examEnums.selectLd',
            conditions: LdConditions,
            deleted: isEmpty(LdConditions),
            onChange: (values) => setld(values),
            onChangeComment: (values) => setldComment(values),
            disable: false,
            data: Ld,
            sectionKey: "LD",
            comments: LdComment,
            generateCommentKey:'lignes_droites_uuid',
            reportTitle: getTitle(Ld, ['repport.table.analyseLd', 'repport.table.analyseLds', 'repport.table.analyseLdh'])
        },
        {
            label: 'examEnums.selectLdFa',
            deleted: isEmpty(LdFAConditions),
            conditions: LdFAConditions,
            onChange: (values) => setldFa(values),
            onChangeComment: (values) => setldFaComment(values),
            disable: false,
            data: LdFa,
            sectionKey: "LDFA",
            comments: LdFaComment,
            generateCommentKey:'ligne_droite_flexions_anterieures_uuid',
            reportTitle: 'repport.table.analyseLdFa'

        },
        {
            label: 'examEnums.selectLdFp',
            conditions: LdFPConditions,
            deleted: isEmpty(LdFPConditions),
            onChangeComment: (values) => setldFpComment(values),
            onChange: (values) => setldFp(values),
            disable: false,
            data: LdFp,
            sectionKey: "LDFP",
            generateCommentKey:'ligne_droite_flexions_posterieures_uuid',
            reportTitle: 'repport.table.analyseLdFp',
            comments: LdFpComment,

        },
        {
            label: 'examEnums.selectLc',
            deleted: isEmpty(LcConditions),
            conditions: LcConditions,
            onChange: (values) => setLc(values),
            onChangeComment: (values) => setLcComment(values),
            disable: false,
            data: Lc,
            sectionKey: "LC",
            comments: LcComment,
            generateCommentKey:'left_circles_uuid',

            reportTitle: getTitle(Lc, ['repport.table.analyseLc', 'repport.table.analyseLcs', 'repport.table.analyseLch'])


        },
        {
            label: 'examEnums.selectRc',
            conditions: RcConditions,
            deleted: isEmpty(RcConditions),
            onChange: (values) => setRc(values),
            onChangeComment: (values) => setRcComment(values),
            disable: false,
            data: Rc,
            sectionKey: "RC",
            comments: RcComment,
            generateCommentKey:'right_circles_uuid',

            reportTitle: getTitle(Rc, ['repport.table.analyseRc', 'repport.table.analyseRcs', 'repport.table.analyseRch'])

        },
    ]


    //Fns
    const totalSteps = () => {
        return steps.filter(e => !e.deleted).length;
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };


    const handleComplete = (complete = true) => {
        const newCompleted = completed;
        newCompleted[activeStep] = complete;
        setCompleted(newCompleted);
        handleNext();
    };


    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        let AS = activeStep - 1
        setActiveStep(AS);
        const newCompleted = completed;
        newCompleted[AS] = false;
        setCompleted(newCompleted);
    };



    const prepareData = () => {
        return {
            "vet_case": VET_ID,
            "exams": [
                examen?.['@id'],
            ],
            "type": LocomotionType.LC,
            "data": {
                sections: steps.filter(e => !e.deleted && !isEmpty(e.data)).map(step => ({
                    "conditions": step.data.map(e => e?.uuid),
                    "comments": step.comments,
                    "section_key": step.sectionKey,
                    "label": step.reportTitle
                })),
                conclusion
            },
            "published": true

        }

    }

    const createRepport = () => {
        let data = prepareData()
        createReprotMutation.mutateAsync(data)
            .then(result => {
                enqueueSnackbar(translate('snackbar.createSuccess'), {
                    variant: 'success'
                })
                navigate(-1)
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
            })
    }
    //End fn



    return (
        <Container maxWidth='lg'>
            {
                isLoading ?
                    <Box display={'flex'} justifyContent={'center'}>
                        <CircularProgress></CircularProgress>
                    </Box>
                    :

                    <Stepper orientation='vertical' activeStep={activeStep}>
                        {steps.filter(e => !e.deleted).map((step, index) => (
                            <Step key={index} completed={completed[index]}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {translate(step.label)}
                                </StepButton>
                                <StepContent>
                                    <ConditionsSelector
                                        examen={examen}
                                        loading={createReprotMutation.isLoading}
                                        createRepport={createRepport}
                                        onChangeComment={values => step.onChangeComment(values)}
                                        onChange={values => {
                                            step.onChange(values)
                                        }}
                                        selected={step.data}
                                        comments={step.comments}
                                        step={index}
                                        totalSteps={totalSteps()}
                                        disabled={step.disable}
                                        conditions={step.conditions}
                                        onNext={handleComplete}
                                        onBack={handleBack}
                                        conclusion={conclusion}
                                        updateConclusion={(text) => setConclusion(text)}
                                        generateCommentKey={step.generateCommentKey}
                                    ></ConditionsSelector>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>}
        </Container>
    )
}

export default CreateNewReport