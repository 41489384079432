import { isNull } from "lodash"
import { SEUILS } from "./Seuils"

export class Threshold {
    constructor(boiterie, condition, indice, part) {
        this.boiterie = this.getBoiterie(boiterie)
        this.condition = this.getCondition(condition)
        this.indice = this.getIndice(indice)
        this.part = this.getPart(part)
        this.item = this.getSeuil()
        console.log({ cond: condition, ind: this.indice });
    }

    getBoiterie(boiterie) {
        switch (boiterie) {
            case 'ba':
                return 'ANT'
            case 'bp':
                return 'POST'
            default:
                return null
        }
    }

    getCondition(condition = { figure: '', ground: '' }) {
        if (condition.figure === 'STRAIGHT_LINE') {
            if (condition.ground === 'HARD') {
                return 'LDD'
            }
        }
        if (condition.figure === 'LEFT_CIRCLE') {
            if (condition.ground === 'HARD') {
                return 'MGD'
            }
            if (condition.ground === 'SOFT') {
                return 'MGS'
            }
        }
        if (condition.figure === 'RIGHT_CIRCLE') {
            if (condition.ground === 'HARD') {
                return 'MDD'
            }
            if (condition.ground === 'SOFT') {
                return 'MDS'
            }
        }
    }


    getIndice(indice) {
        switch (indice) {
            case 'min':
                return "min"
            case 'max':
                return "max"
            case 'up':
                return "up"
            default:
                return null
        }
    }
    getPart(part) {
        switch (part) {
            case 'Tete':
                return "headSI"
            case 'Garrot':
                return "withersSI"
            case 'Croupe':
                return "croupSI"
            default:
                return null
        }
    }

    formattedIndice() {
        return [this.part, this.indice].join('.')
    }

    //Threshold example
    /*     {
            "threshold": {
                "Version": "2022_12_02",
                "Condition": "MGD",
                "Figure": 6,
                "Sol": 1,
                "Type_seuil": "ANT",
                "Indice": "withersSI.min",
                "seuilG_inf": -26.7,
                "seuilG": -22.4,
                "seuilG_sup": -17.7,
                "seuilD_inf": -13.5,
                "SeuilD": -10.8,
                "seuilD_sup": -7.8,
                "Actif": 1
            }
        } */
    //

    getSeuil() {
        try {
            let foundThreshold = SEUILS.find(s => s.Condition === this.condition && s.Type_seuil === this.boiterie && s.Indice === this.formattedIndice())
            return !foundThreshold ? null : foundThreshold
        } catch (err) {
            console.error(err);
            return null
        }
    }


    create() {
        if (isNull(this.item)) return null
        return this.item
    }
}