import { Box, Divider, ListItemText, MenuItem, MenuList, Typography, useTheme } from '@mui/material'
import { get, isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import useLocales from 'src/hooks/useLocales';




OrganisationMenu.defaultProps = {
    cliniques: [],
    onSelect: () => { },
    loading: false
}
export default function OrganisationMenu({ restrictedOrgs, cliniques, onSelect, loading }) {
    const theme = useTheme();
    const { userInOrganisation } = useSelector((state) => state.user);
    const { translate } = useLocales()

    const ORG_ID = useMemo(() => {
        return !get(userInOrganisation, 'restricted', false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id
    }, [userInOrganisation])

    return (
        <Box maxHeight={200} sx={{ overflowY: 'scroll' }}>
            <MenuList sx={{ p: 1 }}>
                {
                    !isEmpty(cliniques) && cliniques.map(clinique => (
                        <MenuItem disabled={loading} key={clinique?.id} onClick={(e) => onSelect(e, clinique?.id, false)}
                        title={clinique?.organisation?.name}
                            sx={{
                                "&.Mui-selected": {
                                    color: theme.palette.primary.main,
                                    background: "initial",
                                    fontWeight: 500,
                                },
                                cursor: "pointer"
                            }}
                            selected={
                                clinique?.id === userInOrganisation?.id
                            }
                        >
                            <ListItemText sx={{overflow:'hidden'}}>
                                <Typography variant='body2' sx={{lineClamp:1}}>{clinique?.organisation?.name}</Typography>
                            </ListItemText>
                        </MenuItem>
                    ))
                }
            </MenuList>
            {
                restrictedOrgs?.length > 0 &&
                <>
                    <Divider sx={{ borderStyle: "dashed" }} >
                        <Typography variant='caption'>{translate('restrictedOrgs')}</Typography>
                    </Divider>
                    <MenuList sx={{ p: 1 }}>
                        {
                            !isEmpty(restrictedOrgs) && restrictedOrgs.map(clinique => (
                                <MenuItem disabled={loading} key={clinique.id} onClick={(e) => onSelect(e, clinique.id, true)}
                                    sx={{
                                        "&.Mui-selected": {
                                            color: theme.palette.primary.main,
                                            background: "initial",
                                            fontWeight: 500,
                                        },
                                        cursor: "pointer"
                                    }}
                                    selected={
                                        clinique.id === ORG_ID
                                    }
                                >
                                    <ListItemText>
                                        <Typography variant='body2' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
                                            {clinique?.name}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </>
            }

        </Box>
    )
}
