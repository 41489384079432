const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    breadcrumbs: [],
    horseRoute: "list"
}

const slice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        setBreadcrumb(state, action) {
            state.breadcrumbs = action.payload
        },
        setRoute(state, action) {
            state.horseRoute = action.payload
        }
    }
})
// Reducer
export default slice.reducer;

export const { setBreadcrumb,setRoute } = slice.actions;
