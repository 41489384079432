import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { List, Collapse } from "@mui/material";
//
import { NavItemExamen, NavItemCondition } from "./NavItem";

// ----------------------------------------------------------------------

NavRootExamen.propTypes = {
  examen: PropTypes.object,
  isCollapse: PropTypes.bool,
  handleClick: PropTypes.func,
  handleLongPress: PropTypes.func,
};

export function NavRootExamen({
  examen,
  isCollapse,
  handleClick,
  handleLongPress,
}) {
  const active = false;

  const [open, setOpen] = useState(active);

  const hasConditions = examen.conditions && examen.conditions.length > 0;

  if (hasConditions) {
    return (
      <>
        <NavItemExamen
          examen={examen}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(examen.conditions || []).map((condition) => (
                <NavItemCondition
                  key={condition.external_id}
                  condition={condition}
                  handleClick={handleClick}
                  handleLongPress={handleLongPress}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <NavItemExamen examen={examen} active={active} isCollapse={isCollapse} />
  );
}
