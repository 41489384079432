import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Grid, Card, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import {
  getOrganisation,
  deleteOrganisation,
} from "../../redux/slices/user";
// hooks
import useLocales from "../../hooks/useLocales";
// components
import { FormProvider, RHFTextField } from "../../components/hook-form";
import { get } from "lodash";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function OrganisationAdvanced() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { userInOrganisation, organisation } = useSelector(
    (state) => state.user
  );

  const UpdateAdvancedOrganisationSchema = Yup.object().shape({
    nameToDelete: Yup.string()
      .required(translate("yup.name.required"))
      .matches(organisation?.name || "----------"),
  });

  const defaultValues = {
    nameToDelete: "",
  };

  const methods = useForm({
    resolver: yupResolver(UpdateAdvancedOrganisationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      dispatch(deleteOrganisation(organisation.id));
      enqueueSnackbar(translate("snackbar.deleteSuccess"));
      navigate(PATH_DASHBOARD.root);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(getOrganisation(!get(userInOrganisation,'restricted',false) ? userInOrganisation?.organisation?.id : userInOrganisation?.id));
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <LabelStyle>{translate("settings.deleteOrganisation")}</LabelStyle>
            <Stack spacing={3} alignItems="left" sx={{ mt: 3 }}>
              <RHFTextField
                disabled={userInOrganisation.type === "MEMBER"}
                name="nameToDelete"
                label={translate("settings.deleteOrganisationConfirm")}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {translate("settings.deleteOrganisationMessage")}
              </Typography>
            </Stack>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                disabled={userInOrganisation.type === "MEMBER"}
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color="error"
              >
                {translate("settings.deleteOrganisation")}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
