import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import orderBy from "lodash/orderBy";
// form
import { useForm } from "react-hook-form";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer, Divider } from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { getExamens, filterExamens } from "../../../redux/slices/examen";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import { NavSectionPlusVertical } from "../../../components/nav-section-plus";
//
import navConfig from "./NavConfig";
import sidebarConfig from "./SidebarConfig";
import NavbarHorse from "./NavbarHorse";
import CollapseButton from "./CollapseButton";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const dispatch = useDispatch();

  const { horse } = useSelector((state) => state.horse);
  const { examen, examens, filters, sortBy } = useSelector(
    (state) => state.examen
  );

  const filteredExamens = applyFilter(examens, sortBy, filters);

  const defaultValues = {
    horseExternalId: filters.horseExternalId,
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, watch, setValue } = methods;

  const values = watch();

  useEffect(() => {
    dispatch(filterExamens(values));
  }, [dispatch, values]);

  useEffect(() => {
    if (horse) {
      setValue("horseExternalId", horse.external_id);
    }
  }, [horse]);

  // console.log("examens : ", examens);
  // console.log("horseExternalId : ", horse && horse.external_id);

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  const tmpConfig = [
    {
      subheader: "examens",
      examens: filteredExamens,
    },
  ];

  // const [tmpConfig, setTmpConfig] = useState([
  //   {
  //     subheader: "examens",
  //     examens: filteredExamens,
  //   },
  // ]);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // useEffect(() => {
  //   const config = JSON.parse(JSON.stringify(tmpConfig));
  //   config.map((group) =>
  //     group.examens.map((ex) => {
  //       if (ex.id === examen.id) {
  //         ex.isSelected = true;
  //       } else {
  //         ex.isSelected = false;
  //       }
  //     })
  //   );
  //   setTmpConfig(config);
  // }, [examen]);

  // function getSelectionLength() {
  //   const config = [...tmpConfig];
  //   var number = 0;
  //   config.map((group) =>
  //     group.examens.map((examen) =>
  //       examen.children.map((child) => {
  //         if (child.isSelected) {
  //           number = number + 1;
  //         }
  //       })
  //     )
  //   );
  //   return number;
  // }

  const handleClick = (id) => {
    // const selection = getSelectionLength();
    // const config = JSON.parse(JSON.stringify(tmpConfig));
    // config.map((group) =>
    //   group.examens.map((examen) =>
    //     examen.children.map((child) => {
    //       if (selection < 2) {
    //         child.isSelected = child.id === id;
    //       } else {
    //         if (child.id === id) {
    //           child.isSelected = !child.isSelected;
    //         }
    //       }
    //     })
    //   )
    // );
    // setTmpConfig(config);
  };

  const handleLongPress = (id) => {
    // const config = [...tmpConfig];
    // config.map((group) =>
    //   group.examens.map((examen) =>
    //     examen.children.map((child) => {
    //       if (child.id === id) {
    //         child.isSelected = !child.isSelected;
    //       }
    //     })
    //   )
    // );
    // setTmpConfig(config);
  };

  // console.log(tmpConfig);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>

      {!isDesktop && (
        <>
          <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
          <Divider />
        </>
      )}

      <NavbarHorse isCollapse={isCollapse} />

      <NavSectionPlusVertical
        navConfig={tmpConfig}
        isCollapse={isCollapse}
        handleClick={handleClick}
        handleLongPress={handleLongPress}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );


  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

function applyFilter(examens, sortBy, filters) {
  // SORT BY
  if (sortBy === "dateDesc") {
    examens = orderBy(examens, ["createdAt"], ["desc"]);
  }

  // FILTER EXAMS
  if (filters.horseExternalId !== "") {
    examens = examens.filter(
      (examen) => examen.horse.external_id === filters.horseExternalId
    );
  } else {
    return [];
  }

  return examens;
}
