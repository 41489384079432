import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  horses: [],
  horse: {},
  sortBy: "nameAsc",
  filters: {
    horseNumber: [],
    name: [],
    owner: [],
    stable: [],
  },
};

const slice = createSlice({
  name: "horse",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET HORSES
    getHorsesSuccess(state, action) {
      state.isLoading = false;
      state.horses = action.payload;
    },

    // GET HORSE
    getHorseSuccess(state, action) {
      state.isLoading = false;
      state.horse = action.payload;
    },

    //  SORT & FILTER HORSES
    sortByHorses(state, action) {
      state.sortBy = action.payload;
    },

    filterHorses(state, action) {
      state.filters.horseNumber = action.payload.horseNumber;
      state.filters.name = action.payload.name;
      state.filters.owner = action.payload.owner;
      state.filters.stable = action.payload.stable;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByHorses, filterHorses } = slice.actions;

// ----------------------------------------------------------------------

export function getHorses(organisationId, horseName = null, page = 1) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      let uri = !horseName ? `organisations/${organisationId}/horses?page=${page}` : `organisations/${organisationId}/horses?name=${horseName}&page=${page}`


      const response = await axios.get(uri);
      // console.log(response.data["hydra:member"]);
      dispatch(slice.actions.getHorsesSuccess(response.data["hydra:member"]));
      return response
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}

// ----------------------------------------------------------------------

export function getHorse(external_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/horses/${external_id}/`);
      dispatch(slice.actions.getHorseSuccess(response.data));
      return response
      // const horse = _horses.filter(
      //   (horse) => horse.external_id === external_id
      // );
      // dispatch(slice.actions.getHorseSuccess(horse[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return error
    }
  };
}
// ----------------------------------------------------------------------
export function getHorseExams(id) {
  return axios.get(`/horses/${id}/exams`)
}

export function getHorsesCall(org_id, page = 1) {
  return axios.get(
    `organisations/${org_id}/horses?page=${page}`
  );
}