import { useState, useEffect } from "react";

import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

// @mui
import { Box } from "@mui/material";
import { LinesMode } from "./EqsWindow";
import { get, isEmpty, set } from "lodash";
import { useRef } from "react";
import { Threshold } from "./Threshold";

highchartsMore(Highcharts);

const sigmaLineHeight = 0.075;

// ----------------------------------------------------------------------
EqsCursor.defaultProps = {
  multi: false
}
export default function EqsCursor({
  dataSet,
  type,
  linesMode,
  strideIndex,
  isActive,
  height,
  selectedThreshold,
  multi
}) {
  const dataSeries = [];
  const chartRef = useRef()
  const [chartOptions, setChartOptions] = useState({
    chart: {
      marginLeft: 0, // Keep all charts left aligned
      marginRight: 0,
      spacingTop: 0,
      spacingBottom: 0,
      backgroundColor: null,
      style: {
        fontFamily: "Public Sans",
      },
    },
    stockTools: {
      gui: {
        enabled: false, // disable the built-in toolbar
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            enabled: false,
          },
        },
      },
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      visible: false,
      crosshair: false,
      min: -100,
      max: 100,
      endOnTick: false,
    },
    yAxis: [
      {
        visible: false,
        title: "",
        crosshair: false,
        min: -0.5,
        max: 0.5,
        endOnTick: false,
        labels: {
          format: "{value}",
        },
        opposite: false,
      },
    ],
    series: dataSeries,
  });

  if (!isEmpty(dataSet)) {
    dataSet.forEach(dataset => {

      const center =
        linesMode === LinesMode.Single
          ? parseInt(dataset?.si[type]?.strides[strideIndex - 1 || 0])
          : parseInt(dataset?.si[type]?.mean);
      const sigma =
        linesMode === LinesMode.Single ? 0 : parseInt(dataset?.si[type]?.std);

      // CENTER
      let chart = chartRef?.current?.chart

      if (chart?.customSvgElems) {
        chart?.customSvgElems.forEach(function (elem) {
          elem?.destroy();
        });
      }
      set(chart, 'customSvgElems', [])
      let YStartPoint = chart?.yAxis[0]?.toPixels(0.05)
      let XStartPoint = chart?.xAxis[0]?.toPixels(-100)
      //dataSet Name (Tete,Croupe,Garrot)
      //Affichage des seuils 
      if (selectedThreshold === 'ba') {
        if (['Tete', 'Garrot'].includes(dataset?.name)) {

          let threshold = new Threshold(selectedThreshold, { figure: dataset.figure, ground: dataset.ground }, type, dataset.name).create()
          if (!threshold) return
          let mid = Math.abs(threshold.seuilD_inf - threshold.seuilG_sup)
          let sg = Math.abs(threshold.seuilG_sup - threshold.seuilG_inf)
          let sd = Math.abs(threshold.seuilD_sup - threshold.seuilD_inf)
          let height = 7
          if (chart) {
            //Width value start from 0 to 200 (equivalent to -100 to 100)
            let t1 = chart.renderer.rect(XStartPoint, YStartPoint, 200, height, 2).attr({
              fill: "rgba(245, 39, 54, 0.5)",
              zIndex: 0,
            }).add();
            let t2 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilG_inf), YStartPoint, sg, height, 2).attr({
              fill: "rgba(252,225,1,0.8)",
              zIndex: 1,
            }).add();
            let t3 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilD_inf), YStartPoint, sd, height, 2).attr({
              fill: "rgba(252,225,1,0.8)",
              zIndex: 1,
            }).add();
            let t4 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilG_sup), YStartPoint, mid, height, 2).attr({
              fill: "rgba(11, 156, 49, 0.8)",
              zIndex: 2,
            }).add();
            //triangles
            let t5 = chart.renderer.label(multi ? '🔻' : '🔺', chart?.xAxis[0]?.toPixels(threshold.seuilG), chart?.yAxis[0]?.toPixels(multi ? 0.7 : 0.03)).add().toFront()
            let t6 = chart.renderer.label(multi ? '🔻' : '🔺', chart?.xAxis[0]?.toPixels(threshold.SeuilD), chart?.yAxis[0]?.toPixels(multi ? 0.7 : 0.03)).add().toFront()
            chart.customSvgElems.push(t1, t2, t3, t4, t5, t6)
          }

        }
      }
      if (selectedThreshold === 'bp') {
        if (['Tete', 'Croupe'].includes(dataset?.name)) {

          let threshold = new Threshold(selectedThreshold, { figure: dataset.figure, ground: dataset.ground }, type, dataset.name).create()

          if (!threshold) return


          let mid = Math.abs(threshold.seuilD_inf - threshold.seuilG_sup)
          let sg = Math.abs(threshold.seuilG_sup - threshold.seuilG_inf)
          let sd = Math.abs(threshold.seuilD_sup - threshold.seuilD_inf)
          let height = 7
          //Width value start from 0 to 200 (equivalent to -100 to 100)
          if (chart) {
            let t1 = chart.renderer.rect(XStartPoint, YStartPoint, 200, height, 2).attr({
              fill: "rgba(245, 39, 54, 0.5)",
              zIndex: 0,
            }).add();
            let t2 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilG_inf), YStartPoint, sg, height, 2).attr({
              fill: "rgba(252,225,1,0.8)",
              zIndex: 1,
            }).add();
            let t3 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilD_inf), YStartPoint, sd, height, 2).attr({
              fill: "rgba(252,225,1,0.8)",
              zIndex: 1,
            }).add();
            let t4 = chart.renderer.rect(chart?.xAxis[0]?.toPixels(threshold.seuilG_sup), YStartPoint, mid, height, 2).attr({
              fill: "rgba(11, 156, 49, 0.8)",
              zIndex: 2,
            }).add();
            //triangles
            let t5 = chart.renderer.label(multi ? '🔻' : '🔺', chart?.xAxis[0]?.toPixels(threshold.seuilG), chart?.yAxis[0]?.toPixels(multi ? 0.7 : 0.03)).add().toFront()
            let t6 = chart.renderer.label(multi ? '🔻' : '🔺', chart?.xAxis[0]?.toPixels(threshold.SeuilD), chart?.yAxis[0]?.toPixels(multi ? 0.7 : 0.03)).add().toFront()
            chart.customSvgElems.push(t1, t2, t3, t4, t5, t6)
          }


        }
      }



      dataSeries.push({
        data: [[center, 0.05]],
        type: "line",
        lineWidth: 1,
        color: dataset.color,
        zIndex: 1000,
        marker: {
          symbol: "square",
          zIndex: 1000,
          height: 20
        },
        dataLabels: {
          enabled: true,
          style: {
            color: 'black',
          },
          formatter: function () {
            return Highcharts.numberFormat(this.x, 0) + "%";
          },
        },
      });
      dataSeries.push({
        data: [[center, -0.05]],
        type: "line",
        lineWidth: 1,
        color: dataset.color,
        zIndex: 1000,
        marker: {
          symbol: "square",
          zIndex: 1000,
          height: 20

        },
      });

      // SIGMA
      if (linesMode === LinesMode.Mean) {
        dataSeries.push({
          data: [
            [center - sigma / 2, -sigmaLineHeight],
            [center - sigma / 2, sigmaLineHeight],
            [center - sigma / 2, 0],
            [center + sigma / 2, 0],
            [center + sigma / 2, sigmaLineHeight],
            [center + sigma / 2, -sigmaLineHeight],
          ],
          type: "line",
          lineWidth: 1.5,
          color: dataset.color,
          marker: {
            enabled: false,
          },
        });
      }
      // POINTS
      else if (linesMode === LinesMode.All) {
        let items = get(dataset, `si[${type}].strides`, [])
        const strides = isEmpty(items) ? [] : items.filter(Number).map((str) => {
          return [Number(str), 0];
        });
        dataSeries.push({
          data: strides,
          type: "scatter",
          lineWidth: 0,
          color: dataset.color,
          marker: {
            enabled: true,
            radius: 2,
            lineWidth: 0.5,
            lineColor: "gray",
          },
        });
      }
    })
  }




  useEffect(() => {
    setChartOptions({
      chart: {
        marginLeft: 0, // Keep all charts left aligned
        marginRight: 0,
        spacingTop: 0,
        spacingBottom: 0,
        backgroundColor: null,
        style: {
          fontFamily: "Public Sans",
        },
      },
      stockTools: {
        gui: {
          enabled: false, // disable the built-in toolbar
        },
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              enabled: false,
            },
            hover: {
              enabled: false,
            },
          },
        },
      },
      title: {
        text: "",
        align: "left",
        style: {
          fontSize: "16px",
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        crosshair: false,
        min: -100,
        max: 100,
        endOnTick: false,
      },
      yAxis: [
        {
          visible: false,
          title: "",
          crosshair: false,
          min: -0.5,
          max: 0.7,
          endOnTick: false,
          labels: {
            format: "{value}",
          },
          opposite: false,
        },
      ],
      series: dataSeries,
    });



  }, [linesMode, strideIndex, type, dataSet, selectedThreshold]);

  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        height: height,
        opacity: isActive ? 1 : 0.2,
        position: 'relative'
      }}
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { height: "100%", width: "100%" } }}
      />
    </Box>
  );
}
