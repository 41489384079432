import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo } from 'react';

import { Close, CompareArrows } from '@mui/icons-material';
import { Button, Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { get, isArray, uniq } from 'lodash';
import { useMutation } from 'react-query';
import { getHorseExams } from 'src/redux/slices/horse';
import { formatDate, getOutils } from 'src/utils/dashboard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
function CompareModal({ horses, open, handleClose }) {

    const [Horses, setHorses] = React.useState([]);
    const [horse, setHorse] = React.useState(null);
    const [outils, setOutils] = React.useState([]);
    const [outil, setOutil] = React.useState(null);
    const [exams, setExams] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    useEffect(() => {
        setHorses(isArray(horses) ? horses : [horses])
    }, [horses])

    const examMutation = useMutation(id => getHorseExams(id))

    useEffect(() => {
        if (horse) {
            examMutation.mutateAsync(horse)
                .then(({ data }) => {
                    let exams = get(data, 'hydra:member', [])
                    let duplicateOutils = exams.map(exam => get(exam, '@type'))
                    setOutils(uniq(duplicateOutils))
                    setExams(exams)
                })

        }
    }, [horse])

    const Exams = useMemo(() => {
        if (exams && outil) {
            return exams.filter(exam => exam["@type"] == outil)
        } else {
            return []
        }
    }, [outil])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelected(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const formatChip = (ids) => {
        let temp = []
        ids.forEach(id => {
            let found = Exams.find(e => e?.external_id == id)
            temp.push(found ? getOutils(get(found, '@type')) + ' - ' + formatDate(get(found, 'timestamp_start.unix')) : id)
        })
        return temp
    }

    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <CompareArrows color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>Comparer des examens</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Content */}
                <Grid container spacing={2} marginTop={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sélectionner un cheval</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={horse}
                                label="horse"
                                onChange={(e) => {
                                    setHorse(e.target.value)
                                }}
                            >
                                {Horses.map(h => <MenuItem key={h?.external_id} value={h?.external_id}>{h?.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sélectionner un outil</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={outil}
                                disabled={examMutation.isLoading}
                                label="outil"
                                onChange={(e) => setOutil(e.target.value)}
                            >
                                {outils.map((o, i) => <MenuItem key={i} value={o}>{getOutils(o)}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sélectionner les Examens à comparer</InputLabel>
                            <Select
                                defaultValue=""
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selected}
                                placeholder='Sélectionner les Examens à comparer'
                                multiple
                                disabled={examMutation.isLoading}
                                label="Age"
                                onChange={handleChange}
                                renderValue={e => <Typography variant='caption'>Sélectionner les Examens à comparer</Typography>}
                            >
                                {Exams.map((exam, i) => <MenuItem key={exam?.external_id} value={exam?.external_id}>{getOutils(get(exam, '@type')) + ' - ' + formatDate(get(exam, 'timestamp_start.unix'))}</MenuItem>
                                )}
                            </Select>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, marginTop: 1 }}>
                                {formatChip(selected).map(s => <Chip key={s} label={s} />)}
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} display='flex' justifyContent='end' flexDirection='row' gap={1}>
                        <Button onClick={handleClose} variant='text'>Annuler</Button>
                        <Button sx={{ marginTop: '0 !important' }} variant='contained'>Comparer</Button>
                    </Grid>
                </Grid>

                {/* End of Content */}
            </Box>
        </Modal>
    )
}


export default CompareModal
