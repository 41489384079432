// ----------------------------------------------------------------------

const navConfig = [
  {
    subheader: "nav.folders",
    items: [{ title: "nav.dashboard", path: "/dashboard", icon: "" }],
  },
];

export default navConfig;
