import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Close, FolderDelete, Search, Share, TopicOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import { useMutation } from 'react-query';
import { get, isEmpty } from 'lodash';
import useLocales from 'src/hooks/useLocales';
import { useState } from 'react';
import { addExamVetCase, deleteExamInVetCase } from 'src/redux/slices/examen';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import DeleteModal from './DeleteModal';
import { formatDate, getOutils, getOutilsColor } from 'src/utils/dashboard';
import { useCallback } from 'react';
import { dateComparatorDesc } from 'src/utils/examDashboard';
import { Examen } from 'src/classes/Examen';
import { ExamExtractor } from 'src/utils/ExamExtractor';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 1
};
function ManageExamModal({ open, handleClose, vetCase, exams, refreshVetCase, horseExams, loading }) {
    const theme = useTheme();
    const { translate } = useLocales()
    const [value, setValue] = React.useState(0);

    const [exam, setExam] = useState([])
    const [autoAdd, setAutoAdd] = useState(get(vetCase,'auto_add_future_horse_exams',false))
    const [itemToRemove, setItemToRemove] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const { enqueueSnackbar } = useSnackbar()

    const examMutation = useMutation((vet_case) => addExamVetCase({ vet_case: vetCase['@id'], list_exams: exam }))
    const updateCaseMutation = useMutation(() => Examen.patchCase({ "auto_add_future_horse_exams": autoAdd }, vetCase?.id))
    const deleteExamMutation = useMutation(() => deleteExamInVetCase(itemToRemove))

    const patchCase = async () => {
        return updateCaseMutation.mutateAsync()
            .then(async (res) => {
                enqueueSnackbar(translate('snackbar.updateSuccess'), {
                    variant: 'success'
                })
                refreshVetCase()
                return res
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
                return err
            })
    }


    const addExam = async () => {

        if (isEmpty(exam)) {
            await patchCase()
            handleClose()
            return
        }

        examMutation.mutateAsync()
            .then(async (res) => {
                enqueueSnackbar(translate('snackbar.caseExamCreateSuccess'), {
                    variant: 'success'
                })
                await updateCaseMutation.mutateAsync()
                refreshVetCase()
                setExam([])
            }).catch(err => {
                enqueueSnackbar(translate('snackbar.examAlreadyExistInVet'), {
                    variant: 'warning'
                })
                setValue(1)
            })
    }

    const deleteExam = () => {
        deleteExamMutation.mutateAsync()
            .then(res => {
                enqueueSnackbar(translate('snackbar.examRemoveSuccess'), {
                    variant: 'success'
                })
                refreshVetCase()
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'warning'
                })
            })
    }

    const sortedHorseExam = useCallback(() => {

        let examsUUids = !isEmpty(exams) ? exams.map(({ exam }) => exam.uuid) : []

        return !isEmpty(horseExams) ? horseExams.filter(horseExam => !examsUUids.includes(horseExam.uuid)).sort((a, b) => dateComparatorDesc(
            formatDate(get(a, 'timestamp_start.unix', null))
            , formatDate(get(b, 'timestamp_start.unix', null)
            ))
        ) : []
    }, [horseExams])

    const sortedExams = useCallback(() => {
        return !isEmpty(exams) ? exams.sort((a, b) => dateComparatorDesc(
            formatDate(get(a, 'exam.timestamp_start.unix', null))
            , formatDate(get(b, 'exam.timestamp_start.unix', null)
            ))
        ) : []
    }, [exams])


    const renderExam = (ids) => {
        let founds = [...sortedHorseExam()].filter((exam) => ids.includes(get(exam, '@id')))
        return (
            <>
                {founds.map(f => (
                    <>
                        <Chip size='small' label={getOutils(get(f, '@type'))} />
                        <Typography variant='caption'>{formatDate(get(f, 'timestamp_start.unix')) + ' - '}</Typography>
                    </>
                ))}

            </>
        )
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                {/* Header */}
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Box display='flex' gap={1} alignItems='center'>
                        <TopicOutlined color='primary' fontSize='small' />
                        <Typography color='primary' variant='h6'>{translate('manageExams')}</Typography>
                    </Box>
                    <IconButton onClick={handleClose}>
                        <Close fontSize='small' />
                    </IconButton>
                </Box>

                {/* Content */}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={translate('addCase.addExam')} />
                    <Tab label={translate('general.exams')} />
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        {/*Add Exam with Autocomplete */}
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{translate('general.exams')}</InputLabel>
                            <Select
                                label="Examens"
                                defaultValue=""
                                disabled={loading}
                                multiple
                                value={exam}
                                onChange={(e) => {
                                    setExam(e.target.value)
                                }}
                                renderValue={renderExam}
                                endAdornment={exam && <IconButton onClick={() => setExam(null)}>
                                    <Close fontSize='small'></Close>
                                </IconButton>
                                }
                            >
                                {sortedHorseExam().map(examen =>
                                    <MenuItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} key={examen?.external_id} value={get(examen, '@id')}>
                                        <Chip size='small' label={getOutils(get(examen, '@type'))} />
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
                                            <Typography variant='caption'>{ExamExtractor.getLastExam(examen)}</Typography>
                                            <Typography fontWeight={500} variant='caption'>{ExamExtractor.getDescription(examen)}</Typography>
                                        </Box>
                                    </MenuItem>)}

                            </Select>
                        </FormControl>
                        <Box display={'flex'} flexDirection={'column'}>
                            <FormControlLabel control={<Checkbox onChange={e => {
                                e.target.checked ? setExam(sortedHorseExam().map(exam => get(exam, '@id'))) : setExam([])
                            }}></Checkbox>} label={translate('selectAll')}></FormControlLabel>
                            <FormControlLabel control={<Checkbox defaultChecked={get(vetCase,'auto_add_future_horse_exams',false)} value={autoAdd} onChange={e => setAutoAdd(e.target.checked)}></Checkbox>} label={translate('autoAddExam')}></FormControlLabel>
                        </Box>
                        <Box display='flex' marginTop={3} justifyContent='end'>
                            <LoadingButton loading={examMutation.isLoading || updateCaseMutation.isLoading} onClick={addExam} variant='contained'>{translate('nav.add')}</LoadingButton>
                        </Box>
                    </TabPanel>


                    <TabPanel value={value} index={1} dir={theme.direction}>
                        {exams.length === 0
                            ?
                            <Box textAlign='center'>
                                <Typography variant='caption'>{translate('snackbar.emptyVetCase')}</Typography>
                            </Box>
                            :
                            <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>
                                {sortedExams().map((exam) => (
                                    <React.Fragment key={exam.id}>
                                        <ListItem
                                            secondaryAction={
                                                <IconButton color='error' onClick={() => {
                                                    setOpenModal(true)
                                                    setItemToRemove(exam.id)
                                                }}>
                                                    <FolderDelete></FolderDelete>
                                                </IconButton>
                                            }
                                            alignItems="center" >
                                            <ListItemText
                                                primary={<Chip sx={{ backgroundColor: getOutilsColor(get(exam, 'exam.@type', null)).background, color: getOutilsColor(get(exam, 'exam.@type', null)).text }} size='small' label={getOutils(get(exam, 'exam.@type', null))}></Chip>}
                                                secondary={<Typography variant='caption'>{ExamExtractor.getLastExam(exam.exam)}</Typography>}
                                            />
                                        </ListItem>
                                        <Divider></Divider>
                                    </React.Fragment>
                                ))}
                            </List>
                        }
                    </TabPanel>
                    <DeleteModal open={openModal}
                        title={translate('modal.removeExam')}
                        subTitle={translate('modal.removeExamConfirm')}
                        handleClose={() => {
                            setExam(null)
                            setOpenModal(false)
                            setItemToRemove(null)
                        }}
                        onCancel={() => {
                            setExam(null)
                            setOpenModal(false)
                            setItemToRemove(null)
                        }}
                        onDelete={deleteExam}
                        loading={deleteExamMutation.isLoading}
                    />
                </SwipeableViews>

                {/* End of Content */}
            </Box>
        </Modal>
    )
}

export default ManageExamModal

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingBlock: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

