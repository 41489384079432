// components
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

const ICONS = {
  user: getIcon("carbon:user-avatar"),
  dashboard: getIcon("mdi:monitor-dashboard"),
  horses: getIcon("ph:horse"),
  tools: getIcon("clarity:tools-line"),
};

const navConfig = [
  {
    subheader: "title",
    items: [
      { title: "nav.dashboard", path: "/dashboard", icon: ICONS.dashboard },
      { title: "nav.horses", path: "/horses", icon: ICONS.horses },
/*       { title: "nav.tools", path: "/tools", icon: ICONS.tools },
 */    ],
  },
];

export default navConfig;
