import { useLocale } from 'antd/es/locale'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

function Comment({ comments }) {
    const {translate} = useLocales()
    
    return (
        <div className='wrapper_padding' >
            <h4>{translate('label.comment')} : </h4>

            <ul>
                {
                    comments.map((comment, i) => (
                        <li className='comment' key={i} style={{ fontSize: '14px', position: 'relative', listStyleType: 'none', ':before': {} }}>
                            {`• ${comment}`}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Comment