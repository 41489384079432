// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import Settings from "./components/settings";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import { ProgressBarStyle } from "./components/ProgressBar";
import NotistackProvider from "./components/NotistackProvider";
import ThemeColorPresets from "./components/ThemeColorPresets";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import './styles/global.css'
// Create a client
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from "@sentry/react";
import './components/exam/style.css'
// ----------------------------------------------------------------------

function App() {

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <ProgressBarStyle />
              {/* <Settings /> */}
              <ScrollToTop />
                <Router />
                {process.env.REACT_APP_ENV === "dev" && <ReactQueryDevtools />}
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
export default Sentry.withProfiler(App)