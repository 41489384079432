import { Add, Close, Delete, Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Checkbox, Chip, CircularProgress, Collapse, Divider, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, OutlinedInput, Skeleton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import gsap from 'gsap'
import { capitalize, debounce, flatten, get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Condition } from 'src/classes/Condition'
import { Examen } from 'src/classes/Examen'
import useLocales from 'src/hooks/useLocales'
import { EqsChart } from 'src/sections/eqs'
import { formatDate } from 'src/utils/dashboard'
import DataTable from '../DataTable'
import ConditionView from './ConditionView'
import axiosInstance from 'src/utils/axios'


function ConditionsSelector({ examen, loading, conditions, onNext, onBack, disabled, step, totalSteps, selected, onChange, comments, onChangeComment, createRepport, updateConclusion, conclusion, generateCommentKey }) {
    //States
    const [suggestions, setSuggestions] = useState([])
    //Hooks
    const { translate } = useLocales()
    const generateCommentsMutation = useMutation((data) => axiosInstance.post(`/exams/${examen?.external_id}/analyzed_results`, data))

    const handleInputChange = (condition, checked) => {
        //Check if condition is checked

        if (checked) {
            let data = [...selected, condition]
            onChange(data)
            return
        } else {
            let findCondition = selected.findIndex(i => i?.uuid === condition?.uuid)
            let temp = [...selected]
            temp.splice(findCondition, 1)
            onChange(temp)
        }
    }

    useEffect(() => {
        if (!isEmpty(selected)) {
            generateComment(selected)
        }
    }, [selected.length])

    useEffect(() => {
        if (!isEmpty(suggestions)) {
            gsap.fromTo('.chip_sug', {
                x: -10,
                opacity: 0
            }, { x: 0, opacity: 1, stagger: 0.1 })
        }
    }, [suggestions])


    const generateComment = (conditions = []) => {
        if (isEmpty(conditions)) {
            setSuggestions([])
            return
        }
        let uuids = conditions.map(c => c.uuid)
        generateCommentsMutation.mutateAsync({ [generateCommentKey]: uuids })
            .then(({ data }) => {
                try {
                    setSuggestions(flatten(Object.values(data)))
                } catch (error) {
                    setSuggestions([])
                }
            }).catch(err => {
                console.log({ err });
            })
    }

    const addNewComment = (text = '') => {
        onChangeComment([...comments, text])
    }

    const deleteComment = (index) => {
        let _comments = [...comments]
        _comments.splice(index, 1)
        onChangeComment(_comments)
    }

    const updateText = debounce((text, i) => {
        let _comments = [...comments]
        _comments.splice(i, 1, text)
        onChangeComment(_comments)

    }, 200)

    const removeUnusedComment = () => {
        onChangeComment(comments.filter(e => !isEmpty(e)))
    }

    const isLastStep = () => {
        return step === totalSteps - 1
    }



    return (
        <Box>
            <List sx={{ maxHeight: '59vh', overflowY: 'auto' }}>
                {
                    conditions.map((condition, i) => (
                        <Box display={'flex'} flexDirection={'column'} key={condition.uuid}>
                            <ConditionView isSelected={selected.map(e => e.uuid).includes(condition.uuid)} examen={examen} condition={condition} key={condition.uuid} handleInputChange={handleInputChange}></ConditionView>
                        </Box>
                    ))
                }



            </List>

            <Box>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <h4 style={{ fontWeight: 500 }}>{capitalize(translate('comment'))}</h4>
                    <IconButton color='primary' onClick={() => addNewComment()}>
                        <Add></Add>
                    </IconButton>
                </Box>
                <Divider></Divider>
                <Stack spacing={1} mt={1}>
                    {comments.map((comment, i) => (
                        <OutlinedInput
                            key={i}
                            placeholder={translate('comment')}
                            defaultValue={comment}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip title={translate('nav.delete')} arrow>
                                        <IconButton
                                            color='error'
                                            aria-label="toggle password visibility"
                                            onClick={() => deleteComment(i)}
                                            edge="end"
                                        >
                                            <Delete></Delete>
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }
                            onChange={e => updateText(e.target.value, i)}
                            size='small'></OutlinedInput>
                    ))}
                </Stack>
                {
                    generateCommentsMutation.isLoading ?
                        <Box display={'flex'} gap={1} mt={1}>
                            {[...Array(4)].map(i => (
                                <Skeleton variant='text' height={30} width={'50%'}></Skeleton>
                            ))}
                        </Box>
                        :
                        <>
                            <Typography mt={1} variant='subtitle2'>{translate('repport.suggestion.title')}</Typography>
                            {!isEmpty(suggestions) ?
                                <>
                                    <Box display={'flex'} gap={1} flexWrap={'wrap'} mt={1}>
                                        {
                                            suggestions.map((s, i) => <Chip onClick={() => addNewComment(s)} className='chip_sug' key={i} color='info' variant='outlined' size='small' label={s}></Chip>)
                                        }
                                    </Box>
                                </> : <Box display={'flex'} justifyContent={'center'} mt={2}>
                                    <Typography variant='caption'>{translate('repport.suggestion.empty')}</Typography>
                                </Box>}
                        </>
                }
                {isLastStep() &&
                    <>
                        <Divider sx={{ my: 2 }}>
                            <Typography variant='caption'>{translate('repport.table.conclusion')}
                            </Typography>
                        </Divider>
                        <TextField value={conclusion} onChange={e => updateConclusion(e.target.value)} fullWidth rows={3} label={translate('repport.table.conclusion')}></TextField>
                    </>
                }
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={1}>
                {
                    step !== 0 ?
                        <Button onClick={onBack}>{translate('nav.back')}</Button> : <Box></Box>
                }
                <LoadingButton loading={loading} disabled={disabled} onClick={() => {
                    if (!isLastStep()) {
                        removeUnusedComment()
                        onNext()
                    } else {
                        createRepport()
                    }
                }}>{!isLastStep() ? capitalize(translate('nav.save')) : capitalize(translate('nav:terminer'))}</LoadingButton>
            </Box>
        </Box>

    )
}

export default ConditionsSelector