import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, Chip, IconButton, ListItemButton, Skeleton } from '@mui/material';
import { Delete, PersonRemove } from '@mui/icons-material';
import { useState } from 'react';
import DeleteModal from './DeleteModal'
import useLocales from 'src/hooks/useLocales';
import { useMutation } from 'react-query';
import { deleteUserVetCase } from 'src/redux/slices/examen';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
export default function UserList({ teamUsers, caseUsers, loading, onRemoveUser }) {

    const [userToRemove, setUserToRemove] = useState(null)
    const [open, setOpen] = useState(false)
    const { translate } = useLocales()

    const mutationDelete = useMutation(() => deleteUserVetCase(userToRemove))

    const { enqueueSnackbar } = useSnackbar()

    const removeUser = () => {
        mutationDelete.mutateAsync()
            .then(() => {
                enqueueSnackbar(translate('snackbar.userRemoveSuccess'), {
                    variant: 'success'
                })
                setUserToRemove(null)
                setOpen(false)
                onRemoveUser()
            })
            .catch(err => {
                enqueueSnackbar(translate('general.error'), {
                    variant: 'error'
                })
            })
    }

    const getLabel = ({user}) => {
        if (isEmpty(user?.firstname) || isEmpty(user?.lastname) ) {
            return user?.email
        }else{
            return user?.lastname + ' ' + user?.firstname
        }
    }
    const getName = (user) => {
        if (user?.lastname) {
            return user?.lastname + ' ' + user?.firstname
        } else {
            return user?.email
        }
    }
    if (loading) {
        return (
            <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>

                {[...Array(4)].map(e => (
                    <>
                        <ListItem alignItems="center">
                            <ListItemAvatar>
                                <Skeleton variant="circular" width={45} height={45}>
                                </Skeleton>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<Skeleton variant="rectangular" width='100%' height={20} />}
                            />

                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </>
                ))}

            </List>
        )
    }

    return (
        <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>
            <Divider>
                <Typography variant='caption' color='grey'>{translate('nav.team')}</Typography>
            </Divider>
            {teamUsers.map(({ user }) => (
                <React.Fragment key={user.id}>
                    <ListItem alignItems="center" >
                        <ListItemAvatar>
                            <Avatar src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={getName(user)}
                        />
                    </ListItem>
                </React.Fragment>
            ))}
            <Divider>
                <Typography variant='caption' color='grey'>{translate('general.members')}</Typography>
            </Divider>
            {
                caseUsers.length === 0
                    ?
                    <Box textAlign='center' marginTop={3} width="100%">
                        <Typography variant='body2'>Aucun utilisateur trouvé</Typography>
                    </Box>
                    : caseUsers.map((user) => (
                        <React.Fragment key={user.id}>
                            <ListItem
                                secondaryAction={
                                    <IconButton color='error' onClick={() => {
                                        setUserToRemove(user.id)
                                        setOpen(true)

                                    }}>
                                        <PersonRemove></PersonRemove>
                                    </IconButton>
                                }
                                alignItems="center" >
                                <ListItemAvatar>
                                    <Avatar>{getLabel(user)?.charAt(0)}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={getLabel(user)}
                                    secondary={user?.user?.pending_invitation && <Chip variant='outlined' color='primary' size='small' label={translate('team.waitingAcceptance')}></Chip>}
                                />

                            </ListItem>
                        </React.Fragment>
                    ))}

            <DeleteModal open={open}
                title={translate('modal.removeUser')}
                subTitle={translate('modal.removeUserConfirm')}
                handleClose={() => {
                    setUserToRemove(null)
                    setOpen(false)
                }}

                onCancel={() => {
                    setUserToRemove(null)
                    setOpen(false)
                }}
                onDelete={removeUser}
                loading={mutationDelete.isLoading}
            />

        </List>
    );
}
UserList.defaultProps = {
    teamUsers: [],
    caseUsers: []
}


