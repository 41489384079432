import { useEffect } from "react";
// redux
import { useDispatch, useSelector } from "../redux/store";
import { getLoggedUser } from "../redux/slices/user";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getLoggedUser());
  // }, [dispatch]);

  return (
    <Avatar
      src={user?.image?.content_url}
      alt={`${user?.firstname} ${user?.lastname}`}
      color={
        user?.image?.content_url
          ? "default"
          : createAvatar(`${user?.firstname} ${user?.lastname}`).color
      }
      {...other}
    >
      {createAvatar(`${user?.firstname} ${user?.lastname}`).name}
    </Avatar>
  );
}
